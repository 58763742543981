import { SET_FONT_FAMILY, SET_PREVIEW_COLOR, SWITCH_TOGGLE } from "./types";

export const handleColorButtonChange = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PREVIEW_COLOR,
      payload: data,
    });
  } catch (error) {}
};

export const handleSwitchChange = () => async (dispatch) => {
  try {
    dispatch({
      type: SWITCH_TOGGLE,
    });
  } catch (error) {}
};

export const setGlobalFontFamily = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_FONT_FAMILY,
      payload: data,
    });
  } catch (error) {}
};