import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    ["Tools"]: "Herramientas",
    ["Shape"]: "Forma",
    ["Administration Tools"]: "Herramientas de administración",
    ["Application Permission"]: "Permiso de aplicación",
    ["Report Permissions"]: "Permisos de informes",
    ["Client Data Maps"]: "Mapas de datos de clientes",
    ["Client Status"]: "Estado del cliente",
    ["Create New Client"]: "Crear nuevo cliente",
    ["3rd Party Diamond Imports"]: "Importaciones de diamantes de terceros",
    ["SOLR Core Client"]: "Cliente principal de SOLR",
    ["Vendor Rating"]: "Calificación del proveedor",
    ["Limits"]: "Límites",
    ["Legacy"]: "Legado",
    ["Console Upload"]: "Carga de consola",
    ["Open Source Mapping"]: "Mapeo de código abierto",
    ["Pending Syncs"]: "Sincronizaciones pendientes",
    ["Transfer ZZ.Gemfind"]: "Transferencia de ZZ.Gemfind",
    ["Login Preferences"]: "Preferencias de inicio de sesión",
    ["Retailer Locator"]: "Localizador de minoristas",
    ["Block Designers For Demo"]: "Diseñadores de bloques para demostración",
    ["Settings"]: "Ajustes",
    ["Setting MarkUp"]: "Configuración de MarkUp",
    ["Permissions"]: "Permisos",
    ["Email Template"]: "Plantilla de correo electrónico",
    ["Data Maps"]: "Mapas de datos",
    ["Metal Market"]: "Mercado de metales",
    ["Trade Shows"]: "Ferias comerciales",
    ["Add Color"]: "Añadir color",
    ["Api Access Token"]: "Token de acceso a API",
    ["Campaigns"]: "Campañas",
    ["Mailing List"]: "Lista de correo",
    ["Compose"]: "Componer",
    ["Report"]: "Informe",
    ["Global Events"]: "Eventos globales",
    ["Syncs"]: "Sincronizaciones",
    ["Tools Reports"]: "Herramientas Informes",
    ["Clients Reports"]: "Informes de clientes",
    ["Permission Reports"]: "Informes de permisos",
    ["Data Related"]: "Datos relacionados",
    ["Jewelry Log Reports"]: "Informes de registro de joyas",
    ["Diamond Report"]: "Informe sobre el diamante",
    ["Diamond Clicks"]: "Clics de diamante",
    ["Extended Diamond Chart"]: "Gráfico de diamantes ampliado",
    ["Diamond Clarity Report"]: "Informe sobre la claridad de los diamantes",
    ["Diamond Color Report"]: "Informe sobre el color de los diamantes",
    ["Diamond Cut Report"]: "Informe de corte de diamantes",
    ["Diamond Shape Report"]: "Informe sobre la forma del diamante",
    ["Diamond Certificate Report"]: "Informe de certificado de diamante",
    ["Diamond Size Report"]: "Informe sobre el tamaño de los diamantes",
    ["Jewelry Report"]: "Informe de joyería",
    ["Jewelry Chart Report"]: "Informe de gráfico de joyería",
    ["Jewelry Category Report"]: "Informe de categorías de joyería",
    ["Jewelry Metal Type Report"]:
      "Informe sobre el tipo de metal de las joyas",
    ["Jewelry Price Report"]: "Informe de precios de joyas",
    ["Jewelry Retailer Clicks Report"]:
      "Informe de clics de minoristas de joyería",
    ["Jewelry Vendor Clicks Report"]: "Informe de clics de vendedores de joyas",
    ["Catalog"]: "Catalogar",
    ["Diamond"]: "Diamante",
    ["Mined Diamond"]: "Diamante extraído",
    ["Color Diamond"]: "Diamante de color",
    ["Lab Diamond"]: "Diamante de laboratorio",
    ["Fancy Diamond"]: "Diamante de fantasía",
    ["Default Filter Setting"]: "Configuración de filtro predeterminada",
    ["Upload History"]: "Historial de subidas",
    ["Add Individual Diamond"]: "Añadir diamante individual",
    ["Add Diamond"]: "Añadir diamante",
    ["Upload Diamond File"]: "Subir archivo de diamante",
    ["Diamond Mapping"]: "Mapeo de diamantes",
    ["Manage FTP Credentials"]: "Administrar credenciales FTP",
    ["Download Product File"]: "Descargar archivo del producto",
    ["Jewelry"]: "Joyas",
    ["Catalog List"]: "Lista de catálogos",
    ["View My Product"]: "Ver mi producto",
    ["Add Product"]: "Agregar producto",
    ["Edit Product"]: "Editar producto",
    ["Upload Product File"]: "Subir archivo de producto",
    ["Manage Collection"]: "Administrar colección",
    ["Product Markup"]: "Marcado del producto",
    ["Add Collection"]: "Agregar colección",
    ["Data Mapping"]: "Mapeo de datos",
    ["Essential"]: "Básico",
    ["Item Identity"]: "Identidad del artículo",
    ["Configurable Products"]: "Productos configurables",
    ["Item Details"]: "Detalles del artículo",
    ["Item Pricing"]: "Precios de los artículos",
    ["Relational Pricing"]: "Precios relacionales",
    ["Cost Basis Pricing"]: "Precios basados ​​en costos",
    ["Item-Promo Specials"]: "Ofertas especiales de artículos promocionales",
    ["Item Media"]: "Elemento multimedia",
    ["Item Sizing"]: "Tamaño del artículo",
    ["Gemstone Details"]: "Detalles de la piedra preciosa",
    ["Semi-Mounts & Ring Builder"]: "Semi-monturas y constructor de anillos",
    ["Watch Details"]: "Ver detalles",
    ["Additional Details"]: "Detalles adicionales",
    ["Purchase Order"]: "Orden de compra",
    ["Purchase Order History"]: "Historial de órdenes de compra",
    ["Update Purchase Order"]: "Actualizar orden de compra",
    ["Marketing"]: "Marketing",
    ["Upload Marketing Material"]: "Subir material de marketing",
    ["Appointment"]: "Cita",
    ["Apps"]: "Aplicaciones",
    ["Diamond Links"]: "Enlaces de diamantes",
    ["Application Preview"]: "Vista previa de la aplicación",
    ["Dealers"]: "Distribuidores",
    ["Mined Diamond MarkUp"]: "Margen de diamante extraído",
    ["Fancy Color MarkUp"]: "Marcado de color elegante",
    ["Lab Grown MarkUp"]: "Marcado cultivado en laboratorio",
    ["Available Filter Settings"]: "Configuraciones de filtro disponibles",
    ["Initial Filter Settings"]: "Configuración inicial del filtro",
    ["Set Initial Filter Settings"]:
      "Establecer la configuración inicial del filtro",
    ["Options"]: "Opciones",
    ["Option"]: "Opción",
    ["Shipping"]: "Envío",
    ["Navigation"]: "Navegación",
    ["Shopping Cart Integration"]: "Integración del carrito de compras",
    ["Shopping Cart Configuration"]: "Configuración del carrito de compras",
    ["API Endpoint and Authentication"]: "Punto final de API y autenticación",
    ["API Endpoint Authentication"]: "Autenticación de punto final de API",
    ["Ring Builder"]: "Constructor de anillos",
    ["Vendors"]: "Vendedores",
    ["Archived Vendors"]: "Proveedores archivados",
    ["Archived Vendor"]: "Proveedor archivado",
    ["Manage Vendors"]: "Administrar proveedores",
    ["Setting Vendors"]: "Configuración de proveedores",
    ["Avaliable Filter Settings"]: "Configuraciones de filtro disponibles",
    ["Diamond Markup"]: "Marcado de diamantes",
    ["Setting Markup"]: "Configuración de marcado",
    ["Master Link"]: "Enlace maestro",
    ["E-mail Templates"]: "Plantillas de correo electrónico",
    ["Stud Builder"]: "Constructor de montantes",
    ["Shopping Cart Integrations"]: "Integraciones de carritos de compra",
    ["Website Sync"]: "Sincronización de sitios web",
    ["Sync History"]: "Historial de sincronización",
    ["OutBound Mapping"]: "Mapeo de salida",
    ["FTP Details"]: "Detalles de FTP",
    ["Watch Customizer"]: "Personalizador de relojes",
    ["Time Pieces"]: "Piezas de relojería",
    ["Pendant Builder"]: "Constructor de colgantes",
    ["Search Feature"]: "Función de búsqueda",
    ["Diamond Search"]: "Búsqueda de diamantes",
    ["Color Diamond Search"]: "Búsqueda de diamantes de colores",
    ["Lab Diamond Search"]: "Búsqueda de diamantes de laboratorio",
    ["Watches Search"]: "Buscar relojes",
    ["Jewelry Search"]: "Búsqueda de joyas",
    ["Community"]: "Comunidad",
    ["Vendor Manager"]: "Gerente de proveedores",
    ["My Vendors"]: "Mis proveedores",
    ["Network"]: "Red",
    ["Out Of Network"]: "Fuera de la red",
    ["New"]: "Nuevo proveedor",
    ["Recently Updated"]: "Actualizado recientemente",
    ["Invite Your Vendors"]: "Invita a tus proveedores",
    ["Popular"]: "Proveedor popular",
    ["Archived"]: "Archivado",
    ["Pending Requests"]: "Solicitud pendiente",
    ["Retailer"]: "Detallista",
    ["Jewelry Data & Permissions"]: "Datos y permisos de joyería",
    ["Pending Jewelry Request"]: "Solicitud de joyería pendiente",
    ["Diamond Data & Permission"]: "Datos y permisos de los diamantes",
    ["Pending Diamond Request"]: "Solicitud pendiente de diamante",
    ["Ring Builder Retailer & Permission"]:
      "Fabricante de anillos Minorista y permiso",
    ["Pending Ring Builder Request"]:
      "Solicitud pendiente de creación de anillos",
    ["Retailer Profile"]: "Perfil del minorista",
    ["Jewelry Chart Reports"]: "Informes de gráficos de joyería",
    ["Help"]: "Ayuda",
    ["Report An Issue"]: "Reportar un problema",
    ["Suggest A New Feature"]: "Sugerir una nueva función",
    ["Watch Training Videos"]: "Ver videos de capacitación",
    ["Edit Profile"]: "Editar perfil",
    ["Manage User"]: "Administrar usuario",
    ["Manage Account"]: "Administrar cuenta",
    ["Manage Language"]: "Administrar idioma",
    ["Manage Currency"]: "Administrar moneda",
    ["Logout"]: "Cerrar sesión",
    ["Top Menu"]: "Menú superior",
    ["Dashboard"]: "Panel",
    ["Contact"]: "Contacto",
    ["Contacts"]: "Contactos",
    ["Admin"]: "Administración",
    ["Conversations"]: "Conversaciones",
    ["Terms of Service"]: "Condiciones de servicio",
    ["Privacy Statement"]: "Declaración de privacidad",
    ["Copyright 2021 GemFind"]: "Derechos de autor 2021 GemFind",
    ["-Server Up-Time: Uptime info not available - please try reloading this page"]:
      "-Tiempo de actividad del servidor: La información sobre el tiempo de actividad no está disponible. Intente volver a cargar esta página.",
    ["JewelCloud Speed Test"]: "Prueba de velocidad de JewelCloud",
    ["-RapNet Prices Updated:"]: "-Precios de RapNet actualizados:",
    ["Client Tools"]: "Herramientas del cliente",
    ["Select Client Type"]: "Seleccione el tipo de cliente",
    ["Client Name"]: "Nombre del cliente",
    ["Selected Client Tools"]: "Herramientas de cliente seleccionadas",
    ["Name"]: "Nombre",
    ["Phone"]: "Teléfono",
    ["Type"]: "Tipo",
    ["Verified"]: "Verificado",
    ["Approved Apps"]: "Aplicaciones Aprobadas",
    ["Users"]: "Usuarios",
    ["Username"]: "Nombre de usuario",
    ["Password"]: "Contraseña",
    ["Edit"]: "Editar",
    ["View Profile"]: "Ver perfil",
    ["Download Inventory"]: "Descargar inventario",
    ["Impersonate JewelCloud"]: "Suplantar a JewelCloud",
    ["Return to JewelCloud as Dealer 720"]:
      "Regresar a JewelCloud como distribuidor 720",
    ["Updated"]: "Actualizado",
    ["Data Rating"]: "Clasificación de datos",
    ["Location"]: "Ubicación",
    ["Active"]: "Activo",
    ["Product Loves"]: "Producto Ama",
    ["Collections"]: "Colecciones",
    ["Items"]: "Elementos",
    ["Contact Name"]: "Nombre de contacto",
    ["Vendor tags"]: "Etiquetas de vendedor",
    ["No tag to display"]: "No hay etiqueta para mostrar",
    ["Brands"]: "Marcas",
    ["Reset Password"]: "Restablecer contraseña",
    ["User Name"]: "Nombre de usuario",
    ["Confirm Password"]: "confirmar Contraseña",
    ["App Activation Control"]: "Control de activación de aplicaciones",
    ["Select Dealer"]: "Seleccionar distribuidor",
    ["Diamond Link"]: "Enlace de diamante",
    ["Diamond Search Links"]: "Enlaces de búsqueda de diamantes",
    ["DS Ecommerce"]: "Comercio electrónico DS",
    ["DS SSL"]: "DS SSL",
    ["DS Show PoweredBy"]: "DS Show Desarrollado por",
    ["DS LabGrownDiamonds"]: "Diamantes cultivados en laboratorio de DS",
    ["Jewel Cloud"]: "Nube de joyas",
    ["API"]: "API",
    ["Direct feed"]: "Alimentación directa",
    ["Windows App"]: "Aplicación de Windows",
    ["ML Legacy"]: "Legado de ML",
    ["ML2"]: "ML2",
    ["ML3"]: "ML3",
    ["ML Ecommerce"]: "Comercio electrónico ML",
    ["ML SSL"]: "ML-SSL",
    ["ML Show PoweredBy"]: "Programa ML desarrollado por",
    ["RB Ecommerce"]: "Comercio electrónico RB",
    ["RB SSL"]: "RB SSL",
    ["RB Show PoweredBy"]: "RB Show Desarrollado por",
    ["Tryon"]: "Probar",
    ["SB Ecommerce"]: "Comercio electrónico SB",
    ["SB SSL"]: "SB-SSL",
    ["SB Show PoweredBy"]: "SB Show Desarrollado por",
    ["PB Ecommerce"]: "Comercio electrónico PB",
    ["PB SSL"]: "Certificado SSL de PB",
    ["PB Show PoweredBy"]: "Programa PB desarrollado por",
    ["Gemfind.TV"]: "Gemfind.TV",
    ["Purchase Order(PO)"]: "Orden de compra (OC)",
    ["Bridal/Fashion Separation"]: "Separación entre novia y moda",
    ["Diamond Group Discount"]: "Descuento para grupos de diamantes",
    ["POS System"]: "Sistema POS",
    ["Submit"]: "Entregar",
    ["Reports Permissions"]: "Permisos de informes",
    ["All"]: "Todo",
    ["Diamond Dealers"]: "Comerciantes de diamantes",
    ["Retailers"]: "Minoristas",
    ["Diamond Reports"]: "Informes de diamantes",
    ["Jewelry Reports"]: "Informes de joyería",
    ["Global Diamond Reports"]: "Informes mundiales sobre diamantes",
    ["Global Jewelry Reports"]: "Informes mundiales sobre joyería",
    ["Client Data Mapping"]: "Mapeo de datos de clientes",
    ["Dealer ID"]: "Identificación del distribuidor",
    ["Dealer Company"]: "Empresa distribuidora",
    ["Website Address"]: "Dirección del sitio web",
    ["Upload"]: "Subir",
    ["InBound Data Map"]: "Mapa de datos entrantes",
    ["Outgoing Data Map"]: "Mapa de datos salientes",
    ["OutBound Sync"]: "Sincronización de salida",
    ["Set Default Mapping"]: "Establecer asignación predeterminada",
    ["Search"]: "Buscar",
    ["Reset"]: "Reiniciar",
    ["InActive"]: "Inactivo",
    ["Permission Type"]: "Tipo de permiso",
    ["Account Type"]: "Tipo de cuenta",
    ["VendorDiamond"]: "ProveedorDiamond",
    ["Vendor"]: "Proveedor",
    ["Vendor Status"]: "Estado del proveedor",
    ["Profile Only"]: "Solo perfil",
    ["Retailer Status"]: "Estado del minorista",
    ["Product Type"]: "Tipo de producto",
    ["Action"]: "Acción",
    ["Cancel"]: "Cancelar",
    ["Save"]: "Ahorrar",
    ["Update Client Status Information"]:
      "Actualizar la información del estado del cliente",
    ["Update Date on which Client Name is Created"]:
      "Fecha de actualización en la que se creó el nombre del cliente",
    ["Add New Client"]: "Agregar nuevo cliente",
    ["Company Name"]: "nombre de empresa",
    ["Street Address"]: "Dirección de la calle",
    ["City"]: "Ciudad",
    ["State"]: "Estado",
    ["Zip Code"]: "Código postal",
    ["Country"]: "País",
    ["Email Address"]: "Dirección de correo electrónico",
    ["Phone Number"]: "Número de teléfono",
    ["Generate Password"]: "Generar contraseña",
    ["Create Client"]: "Crear cliente",
    ["Enable 3rd Party Diamond Imports"]:
      "Habilitar importaciones de diamantes de terceros",
    ["Dealer Name"]: "Nombre del distribuidor",
    ["Enabled"]: "Activado",
    ["Add New Retailer To Core"]: "Agregar nuevo minorista al núcleo",
    ["Select Core"]: "Seleccionar núcleo",
    ["Enter Retailer ID"]: "Introduzca el ID del minorista",
    ["Add"]: "Agregar",
    ["id"]: "Identificación",
    ["Core Name"]: "Nombre del núcleo",
    ["Retailer ID"]: "Identificación del minorista",
    ["Retailer Name"]: "Nombre del minorista",
    ["Vendor Ratings"]: "Calificaciones de proveedores",
    ["Vendor ID"]: "Identificación del proveedor",
    ["Vendor Name"]: "Nombre del vendedor",
    ["System Ratings"]: "Calificaciones del sistema",
    ["Manual Ratings"]: "Calificaciones manuales",
    ["Modified Date"]: "Fecha de modificación",
    ["Save Vendor Rating"]: "Guardar calificación del proveedor",
    ["Limit Retailer To Diamond Dealer"]:
      "Limitar el comercio minorista a los distribuidores de diamantes",
    ["Limit Diamond Dealer To Retailer"]:
      "Limitar el comercio de diamantes al minorista",
    ["Limit Retailer To Vendor"]: "Limitar minorista a vendedor",
    ["Limit Vendor To Retailer"]: "Limitar vendedor a minorista",
    ["Apply Filter"]: "Aplicar filtro",
    ["Rules"]: "Normas",
    ["Selected"]: "Seleccionado",
    ["Delete"]: "Borrar",
    ["Create"]: "Crear",
    ["Download"]: "Descargar",
    ["Rule Type"]: "Tipo de regla",
    ["Vendor/Dealer"]: "Proveedor/Distribuidor",
    ["Created Date"]: "Fecha de creación",
    ["Next"]: "Próximo",
    ["Diamond Dealer"]: "Comerciante de diamantes",
    ["Are you sure to delete this Rule"]:
      "¿Estás seguro de eliminar esta regla?",
    ["THIS CAN NOT BE UNDONE"]: "Esto no se puede deshacer",
    ["Delete Limit Rule"]: "Eliminar regla de límite",
    ["Dealer Markup"]: "Margen del distribuidor",
    ["(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"]:
      "(Herramientas de administración de bases de datos para DealerID 720 (Alex) y 712 (Morgan))",
    ["Add a Dealer to all GFLinks:"]:
      "Agregar un distribuidor a todos los GFLinks:",
    ["Select the DealerID you want to"]: "Seleccione el DealerID que desea",
    ["to all GFLinks"]: "a todos los GFLinks",
    ["Remove a Dealer from all GFLinks:"]:
      "Eliminar un distribuidor de todos los GFLinks:",
    ["Remove"]: "Eliminar",
    ["Add a Dealer to all GFLinks Lab Diamonds:"]:
      "Agregue un distribuidor a todos los diamantes de laboratorio de GFLinks:",
    ["to all GFLinks Lab Diamonds"]:
      "a todos los diamantes de laboratorio de GFLinks",
    ["Remove a Dealer from all GFLinks Lab Diamonds:"]:
      "Eliminar un distribuidor de todos los diamantes de laboratorio de GFLinks:",
    ["Add DealerID"]: "Agregar DealerID",
    ["Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks."]:
      "Eliminar un distribuidor de todos los GFLinks: seleccione el ID del distribuidor que desea eliminar de todos los GFLinks.",
    ["Remove DealerID"]: "Eliminar DealerID",
    ["Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds."]:
      "Agregar un distribuidor a todos los diamantes de laboratorio GFLinks: seleccione el ID del distribuidor que desea agregar a todos los diamantes de laboratorio GFLinks.",
    ["Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds."]:
      "Eliminar un distribuidor de todos los diamantes de laboratorio de GFLinks: seleccione el ID del distribuidor que desea eliminar de todos los diamantes de laboratorio de GFLinks.",
    ["Add Console Upload Dealer Data"]:
      "Agregar consola Cargar datos del distribuidor",
    ["Create FTP"]: "Crear FTP",
    ["Dealer"]: "Distribuidor",
    ["FTP User Name"]: "Nombre de usuario FTP",
    ["FTP Password"]: "Contraseña FTP",
    ["File Type"]: "Tipo de archivo",
    ["Path"]: "Camino",
    ["Add Update"]: "Agregar actualización",
    ["Console Upload Info"]: "Información de carga de la consola",
    ["Console Upload Edit Info"]: "Carga de consola Editar información",
    ["Enter User Name"]: "Introduzca el nombre de usuario",
    ["Enter Password"]: "Introducir contraseña",
    ["Enter Folder Name"]: "Introduzca el nombre de la carpeta",
    ["Are you sure you want to delete"]:
      "¿Estás seguro de que deseas eliminar?",
    ["Are You Sure You Want To Delete This Markup ?"]:
      "¿Está seguro que desea eliminar este marcado?",
    ["Are you sure you want to remove this override?"]:
      "¿Está seguro de que desea eliminar esta anulación?",
    ["Dealer Data"]: "Datos del distribuidor",
    ["Dealer Mapping"]: "Mapeo de distribuidores",
    ["Magento Mapping"]: "Mapeo de Magento",
    ["API URL"]: "URL de la API",
    ["API Key"]: "Clave API",
    ["IsActive"]: "Está activo",
    ["Edit Megento Mapping Info"]: "Editar información de mapeo de Megento",
    ["Edit Open Source Mapping Info"]:
      "Editar información de mapeo de código abierto",
    ["Port No"]: "Puerto No.",
    ["Delete Dealer Mapping"]: "Eliminar la asignación de distribuidores",
    ["Megento Mapping Info"]: "Información sobre mapas de Megento",
    ["Open Source Mapping Info"]: "Información sobre mapeo de código abierto",
    ["Discard All"]: "Descartar todo",
    ["Run Valid Image Application"]: "Ejecutar aplicación de imagen válida",
    ["Sync ID"]: "ID de sincronización",
    ["Status"]: "Estado",
    ["Discard"]: "Desechar",
    ["Source JC ID"]: "Fuente JC ID",
    ["Destination JC ID"]: "Destino JC ID",
    ["Replace All"]: "Reemplazar todo",
    ["Add & Update"]: "Agregar y actualizar",
    ["Transfer"]: "Transferir",
    ["Are you sure you want to move data from"]:
      "¿Está seguro de que desea mover los datos desde?",
    ["account to"]: "cuenta a",
    ["account ? - You have select"]: "cuenta ? - Has seleccionado",
    ["Select Designer for New"]: "Seleccionar diseñador para nuevo",
    ["Note"]: "Nota",
    ["This will feature a specific vendor for the"]:
      "Esto contará con un proveedor específico para el",
    ["New to JewelCloud"]: "Novedades en JewelCloud",
    ["section on the login page"]: "Sección en la página de inicio de sesión",
    ["Upload Logo Image for Designers"]:
      "Subir imagen de logotipo para diseñadores",
    ["Choose Image"]: "Elija una imagen",
    ["No File Choosen"]: "No File Choosen",
    ["Pixels"]: "Píxeles",
    ["Png Format"]: "Formato Png",
    ["Select Designer"]: "Seleccionar diseñador",
    ["Upload Image"]: "Subir imagen",
    ["Select Designer To Show on Login Page"]:
      "Seleccionar diseñador para mostrar en la página de inicio de sesión",
    ["Admin Preferences"]: "Preferencias de administrador",
    ["MasterLink Theme"]: "Tema MasterLink",
    ["Default Theme"]: "Tema predeterminado",
    ["Retailer Locater Black Theme"]: "Localizador de minoristas Tema negro",
    ["Block Designers For Demo Retailer"]:
      "Diseñadores de bloques para minoristas de demostración",
    ["Designer Name"]: "Nombre del diseñador",
    ["Is Blocked ?"]: "¿Está bloqueado?",
    ["Update Selection"]: "Actualizar selección",
    ["Add New"]: "Agregar nuevo",
    ["Page Number"]: "Número de página",
    ["1 Tier PageName"]: "Página de nivel 1",
    ["2 Tier PageName"]: "Nombre de página de 2 niveles",
    ["3 Tier PageName"]: "Nombre de página de 3 niveles",
    ["Vendor Super"]: "Vendedor Super",
    ["Vendor Admin"]: "Administrador de proveedores",
    ["Vendor User"]: "Usuario vendedor",
    ["Vendor-Diamond Super"]: "Proveedor-Diamond Super",
    ["Vendor-Diamond Admin"]: "Administrador de proveedores de diamantes",
    ["Vendor-Diamond User"]: "Usuario Proveedor-Diamond",
    ["Retailer Super"]: "Supermercado minorista",
    ["Retailer Admin"]: "Administrador de minoristas",
    ["Retailer User"]: "Usuario minorista",
    ["Admin Super"]: "Superadministrador",
    ["Admin Admin"]: "Administrador Administrador",
    ["Admin User"]: "Usuario administrador",
    ["Combined Super"]: "Súper combinado",
    ["Combined Admin"]: "Administración combinada",
    ["Combined User"]: "Usuario combinado",
    ["Organization Super"]: "Organización Super",
    ["Email Templates"]: "Plantillas de correo electrónico",
    ["All Dealers"]: "Todos los distribuidores",
    ["Registration Emails"]: "Correos electrónicos de registro",
    ["New Account"]: "Nueva cuenta",
    ["Forgot Password"]: "Has olvidado tu contraseña",
    ["Forgot UserName"]: "Olvidé mi nombre de usuario",
    ["Account Locked"]: "Cuenta bloqueada",
    ["Retailer Emails"]: "Correos electrónicos de minoristas",
    ["Invite New Retailer"]: "Invitar a un nuevo minorista",
    ["Community Emails"]: "Correos electrónicos de la comunidad",
    ["Retailer Request e-Mail to"]:
      "Solicitud de minorista por correo electrónico a",
    ["ACTIVE"]: "ACTIVO",
    ["GF Members"]: "Miembros de GF",
    ["Retailer e-Mail to"]: "Correo electrónico del minorista a",
    ["COMMUNITY MANAGED"]: "GESTIÓN COMUNITARIA",
    ["Non GF Members"]: "Miembros no afiliados a GF",
    ["PROFILE ONLY"]: "SOLO PERFIL",
    ["LIMITED ACCOUNT"]: "CUENTA LIMITADA",
    ["Vendor Decline eMail"]: "Correo electrónico de rechazo del proveedor",
    ["Vendor Accept eMail"]: "El vendedor acepta correo electrónico",
    ["Request to Update Product Data"]:
      "Solicitud de actualización de datos del producto",
    ["Vendor Appointment"]: "Designación de proveedor",
    ["Invite New Vendor"]: "Invitar a un nuevo proveedor",
    ["Contact Emails (MasterLink)"]:
      "Correos electrónicos de contacto (MasterLink)",
    ["Customer Registration"]: "Registro de clientes",
    ["New Appointments"]: "Nuevos nombramientos",
    ["Inquires (Request More Info)"]: "Consultas (Solicitar más información)",
    ["Send Mail to Retailer"]: "Enviar correo al minorista",
    ["Send Mail to Sender"]: "Enviar correo al remitente",
    ["Send Email (Email To Friend)"]:
      "Enviar correo electrónico (enviar correo electrónico a un amigo)",
    ["Send Mail to Friend"]: "Enviar correo a un amigo",
    ["Drop A Hint"]: "Deja una pista",
    ["Send Mail to Recipient"]: "Enviar correo al destinatario",
    ["New Favorite List"]: "Nueva lista de favoritos",
    ["New Favorite Items Added"]: "Nuevos artículos favoritos añadidos",
    ["Customer Reset Password"]: "Restablecimiento de contraseña del cliente",
    ["Customer Reset Password (New)"]:
      "Restablecimiento de contraseña del cliente (nuevo)",
    ["Diamond Search Emails"]: "Correos electrónicos de búsqueda de diamantes",
    ["Request More Info"]: "Solicitar más información",
    ["Email To Friend"]: "Enviar correo electrónico a un amigo",
    ["Schedule View"]: "Vista de programación",
    ["Diamond Detail"]: "Detalle de diamante",
    ["Diamond Compare"]: "Comparación de diamantes",
    ["Diamond Customer Compare"]: "Comparación de clientes de Diamond",
    ["Fancy Color Diamond Search Emails"]:
      "Correos electrónicos de búsqueda de diamantes de colores de lujo",
    ["Color Diamond Detail"]: "Detalle de diamante de color",
    ["Ring Builder Emails"]: "Correos electrónicos de Ring Builder",
    ["Drop A Hint (Infinity)"]: "Deja una pista (Infinito)",
    ["Buy Now (Infinity)"]: "Comprar ahora (Infinity)",
    ["Setting Detail"]: "Detalle de la configuración",
    ["Complete Ring Specifications"]: "Especificaciones completas del anillo",
    ["Pendant Builder Emails"]:
      "Correos electrónicos de constructores de colgantes",
    ["Diamond Request More Info"]: "Diamante Solicitar más información",
    ["Complate Pendant Setting"]: "Configuración completa del colgante",
    ["Stud Builder Emails"]:
      "Correos electrónicos de constructores de montantes",
    ["Request More Retailer"]: "Solicitar más minoristas",
    ["SB To ScheduleView"]: "SB para ver la programación",
    ["SB Complete Earring To ScheduleView"]:
      "Pendiente SB Completo Para ProgramarVer",
    ["Diamond Email To Friend"]: "Correo electrónico de diamante para un amigo",
    ["Store"]: "Almacenar",
    ["Big Emails"]: "Correos electrónicos de gran tamaño",
    ["Email To Customer"]: "Correo electrónico al cliente",
    ["Send Mail to Customer"]: "Enviar correo al cliente",
    ["Send Email"]: "Enviar correo electrónico",
    ["Default Data Map"]: "Mapa de datos predeterminado",
    ["Product ID & Description"]: "Identificación y descripción del producto",
    ["Gemfind Column"]: "Columna de búsqueda de gemas",
    ["Active Status"]: "Estado activo",
    ["Category"]: "Categoría",
    ["Categories"]: "Categorías",
    ["Attributes"]: "Atributos",
    ["Gemstones"]: "Piedras preciosas",
    ["Watches"]: "Relojes",
    ["Save Details"]: "Guardar detalles",
    ["Manage Current Metal Market"]: "Gestionar el mercado actual de metales",
    ["Current Metal Price"]: "Precio actual del metal",
    ["Silver"]: "Plata",
    ["Gold"]: "Oro",
    ["Platinum"]: "Platino",
    ["Palladium"]: "Paladio",
    ["Enter New Metal Type"]: "Introduzca un nuevo tipo de metal",
    ["Trade Show"]: "Demostración comercial",
    ["Show"]: "Espectáculo",
    ["Dates"]: "Fechas",
    ["Add Trade Show"]: "Agregar feria comercial",
    ["Edit Trade Show"]: "Editar feria comercial",
    ["JPG Format"]: "Formato JPG",
    ["Show Name"]: "Mostrar nombre",
    ["Website"]: "Sitio web",
    ["Show Start Time"]: "Mostrar hora de inicio",
    ["Show End Time"]: "Mostrar hora de finalización",
    ["AM"]: "SOY",
    ["Closed"]: "Cerrado",
    ["PM"]: "P.M",
    ["Show Start Dates"]: "Mostrar fechas de inicio",
    ["Show End Dates"]: "Mostrar fechas de finalización",
    ["Delete Trade Show"]: "Eliminar feria comercial",
    ["Are You Sure Want to delete"]: "¿Estás seguro de que quieres eliminar?",
    ["Sr. No."]: "Sr. Nro.",
    ["Color Name"]: "Nombre del color",
    ["Edit Color"]: "Editar color",
    ["API Type"]: "Tipo de API",
    ["Update"]: "Actualizar",
    ["Mailing Lists"]: "Listas de correo",
    ["Alias Address"]: "Dirección de alias",
    ["Number Of Recipients"]: "Número de destinatarios",
    ["Created"]: "Creado",
    ["Recipients"]: "Destinatarios",
    ["Sync"]: "Sincronizar",
    ["Recipient Address"]: "Dirección del destinatario",
    ["Client"]: "Cliente",
    ["Select Campaign"]: "Seleccionar campaña",
    ["Campaign Name"]: "Nombre de la campaña",
    ["Submitted"]: "Enviado",
    ["Delivered"]: "Entregado",
    ["Total Opens"]: "Total de aperturas",
    ["Total Clicks"]: "Clics totales",
    ["Select Compose"]: "Seleccionar Redactar",
    ["From Name"]: "De Nombre",
    ["From Email"]: "Desde el correo electrónico",
    ["Type Your Subject"]: "Escribe tu asunto",
    ["Send"]: "Enviar",
    ["From Date"]: "Desde Fecha",
    ["To Date"]: "Hasta la fecha",
    ["Initiated"]: "Iniciado",
    ["Complete"]: "Completo",
    ["Sync Type"]: "Tipo de sincronización",
    ["Show Full Syncs"]: "Mostrar sincronizaciones completas",
    ["Show Update Syncs"]: "Mostrar sincronizaciones de actualización",
    ["Show Delete Syncs"]: "Mostrar Eliminar sincronizaciones",
    ["Show Price Syncs"]: "Mostrar precios sincronizados",
    ["Sync Status"]: "Estado de sincronización",
    ["Show Success Syncs"]: "Mostrar sincronizaciones exitosas",
    ["Show Failed Syncs"]: "Mostrar sincronizaciones fallidas",
    ["Show Pending Syncs"]: "Mostrar sincronizaciones pendientes",
    ["Mastelink"]: "Enlace maestro",
    ["Watch Builder"]: "Constructor de relojes",
    ["Version"]: "Versión",
    ["Diamonds"]: "Diamantes",
    ["Open Requests"]: "Solicitudes abiertas",
    ["Last Updated"]: "Última actualización",
    ["Days Old"]: "Días de antigüedad",
    ["Details"]: "Detalles",
    ["Metal Types"]: "Tipos de metales",
    ["Metal Colors"]: "Colores de metal",
    ["Diamond Pairs"]: "Pares de diamantes",
    ["Default Mapping Values"]: "Valores de mapeo predeterminados",
    ["Heirachy"]: "Jerarquía",
    ["Count"]: "Contar",
    ["Last Upload Date"]: "Fecha de última subida",
    ["Select Diamond"]: "Seleccionar Diamante",
    ["Top Diamond Attribute Values"]:
      "Valores de los atributos principales de los diamantes",
    ["Recent Diamond Attribute Searches"]:
      "Búsquedas recientes de atributos de diamantes",
    ["Depth"]: "Profundidad",
    ["Table"]: "Mesa",
    ["Measurements"]: "Medidas",
    ["Date"]: "Fecha",
    ["Shape"]: "Forma",
    ["Size"]: "Tamaño",
    ["Color"]: "Color",
    ["Cut"]: "Cortar",
    ["Clarity"]: "Claridad",
    ["Price"]: "Precio",
    ["UsersIPAddress"]: "Dirección IP del usuario",
    ["Day & Time"]: "Día y hora",
    ["Certificates"]: "Certificados",
    ["Retail Price"]: "Precio al por menor",
    ["Cost"]: "Costo",
    ["Extended Diamond Chart Reports"]:
      "Informes de gráficos de diamantes ampliados",
    ["Vendor(s)"]: "Proveedor(es)",
    ["Retailer(s)"]: "Minorista(s)",
    ["Diamond Cut Searches"]: "Búsquedas de tallas de diamantes",
    ["Diamond Shape Searches"]: "Búsquedas de formas de diamantes",
    ["Diamond Clarity Searches"]: "Búsquedas de claridad de diamantes",
    ["Diamond Certificate Searches"]: "Búsqueda de certificados de diamantes",
    ["Displays the total number of jewelry clicks through the currently specified date range above"]:
      "Muestra el número total de clics en joyas durante el rango de fechas especificado actualmente arriba",
    ["Download CSV"]: "Descargar CSV",
    ["Total Jewelry Clicks"]: "Total de clics en joyería",
    ["Jewelry Clicks Based on Category"]: "Clics en joyas según categoría",
    ["Jewelry Clicks Based on Price"]:
      "Clics de joyería basados ​​en el precio",
    ["Jewelry Clicks Based on Vendor"]: "Clics de joyería según el proveedor",
    ["Product Image"]: "Imagen del producto",
    ["User IP Address"]: "Dirección IP del usuario",
    ["Collection"]: "Recopilación",
    ["Style Number"]: "Número de estilo",
    ["Metal Type"]: "Tipo de metal",
    ["Metal Color"]: "Color del metal",
    ["Show Filter"]: "Mostrar filtro",
    ["Hide Filter"]: "Ocultar filtro",
    ["Shapes"]: "Formas",
    ["Round"]: "Redondo",
    ["Oval"]: "Oval",
    ["Circel"]: "Círculo",
    ["Heart"]: "Corazón",
    ["Emarald"]: "Esmeralda",
    ["Radiant"]: "Radiante",
    ["Caret"]: "Signo de intercalación",
    ["Carats"]: "Quilates",
    ["Carat"]: "Quilate",
    ["Polish"]: "Polaco",
    ["Length(mm)"]: "Longitud (mm)",
    ["Width(mm)"]: "Ancho (mm)",
    ["height(mm)"]: "altura(mm)",
    ["Symmetry"]: "Simetría",
    ["Girdle"]: "Faja",
    ["Crown Angle"]: "Angulo de la corona",
    ["Pavilion Angle"]: "Ángulo del pabellón",
    ["Culet"]: "Culeta",
    ["Culet Condition"]: "Condición de culet",
    ["Fluorescence"]: "Fluorescencia",
    ["Fancy Color"]: "Color de fantasía",
    ["Intensity"]: "Intensidad",
    ["Overtone"]: "Armónico",
    ["Matched Pairs"]: "Pares emparejados",
    ["Has Video"]: "Tiene video",
    ["Reset Filters"]: "Restablecer filtros",
    ["Apply Filters"]: "Aplicar filtros",
    ["You Have"]: "Tienes",
    ["View"]: "Vista",
    ["Print"]: "Imprimir",
    ["View Details"]: "Ver detalles",
    ["Contact Dealer"]: "Contactar al distribuidor",
    ["Message to B&H Diamonds"]: "Mensaje para B&H Diamonds",
    ["Contact the dealer regarding"]:
      "Póngase en contacto con el distribuidor en relación con",
    ["GemFind"]: "Búsqueda de gemas",
    ["Carat Weight"]: "Peso en quilates",
    ["Treatment"]: "Tratamiento",
    ["Crown"]: "Corona",
    ["Lab"]: "Laboratorio",
    ["Matched Pair Stock"]: "Stock de pares combinados",
    ["Seller"]: "Vendedor",
    ["Stock"]: "Existencias",
    ["Stock #"]: "Existencias #",
    ["Off %"]: "Apagado %",
    ["Flour"]: "Harina",
    ["Dep."]: "Diputado.",
    ["Measure"]: "Medida",
    ["Cert."]: "Certificado.",
    ["Sym"]: "Símbolo",
    ["Image/Video"]: "Imagen/Video",
    ["Video"]: "Video",
    ["Close"]: "Cerca",
    ["Colors"]: "Bandera",
    ["Blue"]: "Azul",
    ["Orange"]: "Naranja",
    ["Green"]: "Verde",
    ["Yellow"]: "Amarillo",
    ["Red"]: "Rojo",
    ["Maroon"]: "Granate",
    ["Permission"]: "Permiso",
    ["Fancy Intensity"]: "Intensidad de fantasía",
    ["Overtone 1"]: "Armónico 1",
    ["Overtone 2"]: "Armónico 2",
    ["Overall Price"]: "Precio total",
    ["Price per Caret Range"]: "Rango de precios por cursor",
    ["Eye Clean"]: "Limpieza de ojos",
    ["Fluorescence Intensity"]: "Intensidad de fluorescencia",
    ["Fluorescence Color"]: "Color de fluorescencia",
    ["L/W Ratio Measurements"]: "Mediciones de la relación L/W",
    ["LW Ratio"]: "Relación LW",
    ["Any"]: "Cualquier",
    ["Square"]: "Cuadrado",
    ["Rectangle"]: "Rectángulo",
    ["Shade"]: "Sombra",
    ["Central Inclusion Range"]: "Cordillera de inclusión central",
    ["Black Inclusion Range"]: "Gama de inclusión negra",
    ["Milky Inclusion Range"]: "Gama de inclusiones lechosas",
    ["Girdle Range"]: "Gama de fajas",
    ["Crown Angle % Range"]: "Rango porcentual del ángulo de la corona",
    ["Pavilion Angle % Range"]: "Rango porcentual del ángulo del pabellón",
    ["Key to Symbols"]: "Clave de símbolos",
    ["Culet Range"]: "Cordillera Culet",
    ["Culet Condition Range"]: "Rango de condición de Culet",
    ["price(Cost)"]: "Precio(costo)",
    ["There are"]: "Hay",
    ["results for your search"]: "resultados para su búsqueda",
    ["Material Type"]: "Tipo de material",
    ["Gender"]: "Género",
    ["Material Color"]: "Color del material",
    ["Gemstone"]: "Piedra preciosa",
    ["Retaile Stock #"]: "Stock minorista #",
    ["Retail"]: "Minorista",
    ["Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."]:
      "El género no es un campo obligatorio para los datos del proveedor, por lo que al dejarlo en blanco, la mayoría de los resultados de productos se considerarán mujeres.",
    ["Please select a vendor before choosing a collection."]:
      "Seleccione un proveedor antes de elegir una colección.",
    ["Retail Stock #"]: "Stock minorista #",
    ["$ cost"]: "$ costo",
    ["$ Retail"]: "$ Venta al por menor",
    ["Store Name"]: "Nombre de la tienda",
    ["Retailer Type"]: "Tipo de minorista",
    ["Requested Date"]: "Fecha solicitada",
    ["Request Date"]: "Fecha de solicitud",
    ["My Retailer"]: "Mi minorista",
    ["My Retailers"]: "Mis minoristas",
    ["Jewelry API"]: "API de joyería",
    ["All Retailer"]: "Todos los minoristas",
    ["All Retailers"]: "Todos los minoristas",
    ["Profile"]: "Perfil",
    ["Conversation"]: "Conversación",
    ["Notes"]: "Notas",
    ["Appointments"]: "Equipo",
    ["Time"]: "Tiempo",
    ["Buyer Name"]: "Nombre del comprador",
    ["Action Request"]: "Solicitud de acción",
    ["Activity"]: "Actividad",
    ["Customer"]: "Cliente",
    ["Load More"]: "Cargar más",
    ["View My Mined Diamond"]: "Ver mi diamante extraído",
    ["Are you sure to delete this diamond"]:
      "¿Estás seguro de eliminar este diamante?",
    ["View My Lab Diamond"]: "Ver mi diamante de laboratorio",
    ["View My Color Diamond"]: "Ver mi diamante de color",
    ["Diamond Upload History"]: "Historial de carga de diamantes",
    ["Data Upload"]: "Carga de datos",
    ["Image Upload"]: "Subir imagen",
    ["File"]: "Archivo",
    ["Records"]: "Archivos",
    ["Inventory #"]: "Inventario #",
    ["Matched Pair Stock #"]: "Par combinado Stock #",
    ["Stones (Parcel)"]: "Piedras (Parcela)",
    ["Separable"]: "Separable",
    ["Fancy Codes"]: "Códigos de fantasía",
    ["Fancy Names"]: "Nombres elegantes",
    ["Other"]: "Otro",
    ["Cut Grade"]: "Grado de corte",
    ["Certificate #"]: "Certificado #",
    ["Pavillion Angle"]: "Ángulo del pabellón",
    ["Comments"]: "Comentarios",
    ["Store Location"]: "Ubicación de la tienda",
    ["Employee Name"]: "Nombre del empleado",
    ["Employee ID"]: "Identificación del empleado",
    ["Out On Memo"]: "Fuera de memorando",
    ["Seen by Other Dealers"]: "Visto por otros distribuidores",
    ["Add Video"]: "Agregar video",
    ["Upload Your Data File"]: "Sube tu archivo de datos",
    ["Add Individual"]: "Agregar individuo",
    ["Upload Certs"]: "Subir certificados",
    ["Download Data"]: "Descargar datos",
    ["Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."]:
      "Mantenga actualizado su inventario. Su inventario de diamantes se eliminará si no se actualiza al menos cada 48 horas.",
    ["Choose File"]: "Seleccione Archivo",
    ["Xls/Txt/Csv Format"]: "Formato XLS/TXT/CVS",
    ["Xls/Txt/Csv/Json Format"]: "Formato XLS/TXT/Csv/JSON",
    ["Upload Data"]: "Subir datos",
    ["Upload Diamond Images & Videos"]: "Subir imágenes y vídeos de diamantes",
    ["Jpg/Png Format"]: "Formato jpg/png",
    ["Compress images together & upload in a .zip format."]:
      "Comprime las imágenes y cárgalas en formato .zip.",
    ["Download our image guidelines documentation"]:
      "Descargue nuestra documentación sobre pautas de imagen",
    ["Import Data From 3rd Party Provider"]:
      "Importar datos de un proveedor externo",
    ["Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit"]:
      "Las cargas de diamantes están limitadas a 50 000 diamantes para garantizar la velocidad y la confiabilidad del sistema. Los archivos de diamantes con más de 50 000 diamantes se eliminarán, por lo que debe consultar a sus proveedores de diamantes para asegurarse de que se encuentra dentro del límite de diamantes.",
    ["Add new diamonds in bulk or make mass item updates"]:
      "Agregue nuevos diamantes en masa o realice actualizaciones masivas de elementos",
    ["First time importing?"]: "¿Es tu primera vez importando?",
    ["Import Your Content"]: "Importa tu contenido",
    ["Choose & upload your data or connect to a 3rd party"]:
      "Elige y carga tus datos o conéctate a un tercero",
    ["Wait For Confirmation"]: "Esperar confirmación",
    ["You will receive an e-mail confirmation when your content has been uploaded."]:
      "Recibirás una confirmación por correo electrónico cuando se haya cargado tu contenido.",
    ["Inventory with Dealer Stock # not included in the upload will be deleted."]:
      "Se eliminará el inventario con número de stock del distribuidor no incluido en la carga.",
    ["Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."]:
      "Se agregará el inventario con el número de stock del nuevo concesionario y se actualizará el número de stock del concesionario existente. El número de stock del concesionario que no esté incluido permanecerá intacto.",
    ["Select Data file to be"]: "Seleccione el archivo de datos que desea",
    ["Mapped"]: "Mapeado",
    ["Upload File"]: "Subir archivo",
    ["Reset the data map"]: "Restablecer el mapa de datos",
    ["View / Edit the map"]: "Ver / Editar el mapa",
    ["Manage FTP settings"]: "Administrar la configuración de FTP",
    ["Generate File"]: "Generar archivo",
    ["GF Column ID"]: "Identificación de la columna GF",
    ["Your Column"]: "Tu columna",
    ["Manage FTP Credential"]: "Administrar credenciales FTP",
    ["FTP URL"]: "URL de FTP",
    ["Request Access"]: "Solicitar acceso",
    ["Your Diamonds"]: "Tus diamantes",
    ["Connected Diamonds"]: "Diamantes conectados",
    ["Total Diamonds"]: "Total de diamantes",
    ["Lab Diamonds"]: "Diamantes de laboratorio",
    ["Color Diamond Count"]: "Recuento de diamantes de color",
    ["Your Products"]: "Sus productos",
    ["Connected Products"]: "Productos conectados",
    ["Active Products"]: "Productos activos",
    ["EDGE"]: "BORDE",
    ["Import Type"]: "Tipo de importación",
    ["Update History"]: "Historial de actualizaciones",
    ["Create Product"]: "Crear producto",
    ["More Actions"]: "Más acciones",
    ["Total"]: "Total",
    ["Product"]: "Producto",
    ["Select All"]: "Seleccionar todo",
    ["Images"]: "Imágenes",
    ["Style No"]: "Estilo No.",
    ["GFinventory No"]: "Número de inventario GF",
    ["Product Name"]: "Nombre del producto",
    ["Wholesale Price"]: "Precio al por mayor",
    ["Gemfind#"]: "Descubrimiento de gemas#",
    ["Feature"]: "Característica",
    ["Are you sure you want to delete this Product(s)?"]:
      "¿Está seguro de que desea eliminar este(os) producto(s)?",
    ["Add Image"]: "Añadir imagen",
    ["OR"]: "O",
    ["Load Image From Server"]: "Cargar imagen desde el servidor",
    ["Load Image"]: "Cargar imagen",
    ["Retail Description"]: "Descripción del comercio minorista",
    ["Style #"]: "Estilo #",
    ["This must be a unique item number identifying the particular item or the"]:
      "Este debe ser un número de artículo único que identifique el artículo en particular o el",
    ["parent"]: "padre",
    ["item which has many variations - if this is a style root then you will want to set this iem as a"]:
      "elemento que tiene muchas variaciones: si se trata de una raíz de estilo, entonces querrá configurar este iem como un",
    ["configurable product"]: "producto configurable",
    ["to configure the variables such as carat weight, metal type, diamond quality, length etc."]:
      "para configurar las variables como el peso en quilates, el tipo de metal, la calidad del diamante, la longitud, etc.",
    ["Dealer Stock #"]: "Stock del distribuidor #",
    ["This is a unique identification number of this particular item."]:
      "Este es un número de identificación único de este artículo en particular.",
    ["Period"]: "Período",
    ["Visibility"]: "Visibilidad",
    ["Additional"]: "Adicional",
    ["Qty On Hand"]: "Cantidad disponible",
    ["Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."]:
      "Pregúntenos cómo podemos establecer una conexión automatizada a su sistema de inventario y rastrear dinámicamente la disponibilidad de su inventario virtual.",
    ["Display Order"]: "Orden de visualización",
    ["This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."]:
      "Se refiere al orden en el que aparecerá el artículo dentro de su categoría: el número más bajo primero. Los artículos con el mismo número se ordenan del más barato al más caro.",
    ["Setting"]: "Configuración",
    ["Finishing Technique"]: "Técnica de acabado",
    ["Clasp Type"]: "Tipo de cierre",
    ["Chain Type"]: "Tipo de cadena",
    ["Back Finding"]: "Hallazgo de regreso",
    ["Additional Information"]: "información adicional",
    ["Additional Information 2"]: "Información adicional 2",
    ["Sizing"]: "Apresto",
    ["General"]: "General",
    ["Weight"]: "Peso",
    ["grams"]: "gramos",
    ["dwt"]: "peso muerto",
    ["Thickness"]: "Espesor",
    ["mm"]: "mm",
    ["inches"]: "pulgadas",
    ["Width"]: "Ancho",
    ["Dimensions"]: "Dimensiones",
    ["Unit Of Measure"]: "Unidad de medida",
    ["Finger Size"]: "Tamaño del dedo",
    ["Stock Finger Size"]: "Tamaño de dedo en stock",
    ["Min Finger Size"]: "Tamaño mínimo del dedo",
    ["Max Finger Size"]: "Tamaño máximo de dedo",
    ["Finger Size Increment"]: "Incremento del tamaño del dedo",
    ["Price Type"]: "Tipo de precio",
    ["Additional Price"]: "Precio adicional",
    ["Pricing"]: "Precios",
    ["Wholesale Price Method"]: "Método de precio al por mayor",
    ["'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"]:
      '"Manual" significa que debe establecer el precio manualmente. "Relacional" significa que el sistema GF utiliza un "Factor de precio relacional" para ajustar el precio en función del precio actual del mercado de metales. "Base de costo" significa que usted proporciona el desglose de componentes del artículo y ajusta los factores de precio globales para recalcular el precio cada día en función de las condiciones actuales del mercado de metales. Si el campo de precio mayorista O los campos de precio minorista están en blanco, entonces el sistema mostrará "Solicitar precio".',
    ["Wholesale Base Price"]: "Precio base al por mayor",
    ["Base Metal Market"]: "Mercado de metales básicos",
    ["Price Factor"]: "Factor precio",
    ["Calculate"]: "Calcular",
    ["Relational Price"]: "Precio relacional",
    ["Relational Metal Market"]: "Mercado de metales relacionales",
    ["Metal Labor Code"]: "Código del Trabajo del Metal",
    ["Other Labor Code"]: "Otros Códigos del Trabajo",
    ["Metal Factor Code"]: "Código de factor de metal",
    ["GPM Code"]: "Código GPM",
    ["Retail Price Method"]: "Método de precio al por menor",
    ["MSRP"]: "Precio de venta sugerido por el fabricante",
    ["To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At."]:
      'Para mejorar la calidad de los datos, háganos saber cómo desea que se gestionen los precios de este producto. 1 - Aplicar el precio de venta sugerido por el fabricante. 2 - Proporcionar un "Precio de venta sugerido por el fabricante" y permitir márgenes de venta minorista. 3 - Permitir márgenes de venta minorista. 4 - Llamar para consultar el precio. 5 - Precios a partir de.',
    ["Related"]: "Relacionado",
    ["Matching Styles"]: "Estilos a juego",
    ["Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."]:
      "Los SKU coincidentes son artículos del mismo conjunto, como un anillo, unos pendientes y un colgante a juego. Ingrese el ID del distribuidor o el número de estilo separados por comas.",
    ["Up Sell Styles"]: "Estilos de venta adicional",
    ["Up sell Sku's are items that are related by at slightly higher price points."]:
      "Los SKU de venta adicional son artículos que están relacionados pero tienen precios ligeramente más altos.",
    ["Grouped Product Styles"]: "Estilos de productos agrupados",
    ["There is no content."]: "No hay contenido.",
    ["Media"]: "Medios de comunicación",
    ["Choose Video"]: "Seleccione el vídeo",
    ["Load Video From URL"]: "Cargar vídeo desde URL",
    ["Load Video"]: "Cargar video",
    ["Add Video Type"]: "Agregar tipo de video",
    ["Label"]: "Etiqueta",
    ["Bullet Point 1"]: "Punto 1",
    ["Bullet Point 2"]: "Punto 2",
    ["Bullet Point 3"]: "Punto 3",
    ["Bullet Point 4"]: "Punto 4",
    ["Bullet Point 5"]: "Punto 5",
    ["Discount A"]: "Descuento A",
    ["Qty 1"]: "Cantidad 1",
    ["Qty 2"]: "Cantidad 2",
    ["Qty 3"]: "Cantidad 3",
    ["Qty 4"]: "Cantidad 4",
    ["Qty 5"]: "Cantidad 5",
    ["Retailer Brand Name"]: "Nombre de marca del minorista",
    ["Secondary Metal Type"]: "Tipo de metal secundario",
    ["Amazon Product"]: "Producto de Amazon",
    ["Total Diamond Weight"]: "Peso total del diamante",
    ["Total Gemstone Weight"]: "Peso total de la piedra preciosa",
    ["Gemstone Type"]: "Tipo de piedra preciosa",
    ["Dimension Unit Of Measure"]: "Unidad de medida de dimensión",
    ["Number Of Gemstones"]: "Número de piedras preciosas",
    ["Gemstone Shape"]: "Forma de la piedra preciosa",
    ["Gemstone Origin"]: "Origen de las piedras preciosas",
    ["Gemstone Carat Weight"]: "Peso en quilates de las piedras preciosas",
    ["Gemstone Dimensions"]: "Dimensiones de las piedras preciosas",
    ["Gemstone Quality"]: "Calidad de las piedras preciosas",
    ["Watch"]: "Mirar",
    ["Band Material"]: "Material de la banda",
    ["Number Type"]: "Tipo de número",
    ["Band Type"]: "Tipo de banda",
    ["Case Material"]: "Material de la caja",
    ["Case Shape"]: "Forma de la caja",
    ["Comes Packaged In"]: "Viene empaquetado en",
    ["Crystal Type"]: "Tipo de cristal",
    ["Dial Color"]: "Color de la esfera",
    ["Manufacture Date"]: "Fecha de fabricación",
    ["Display Type"]: "Tipo de pantalla",
    ["Certification"]: "Proceso de dar un título",
    ["Movement"]: "Movimiento",
    ["Energy"]: "Energía",
    ["Save Product"]: "Guardar producto",
    ["Cancel Product"]: "Cancelar producto",
    ["Upload Your Images"]: "Sube tus imágenes",
    ["Please wait while file is Uploading.."]:
      "Por favor espere mientras se carga el archivo.",
    ["Backgrounds should be white or transparent."]:
      "Los fondos deben ser blancos o transparentes.",
    ["Images should be larger than 500 x 500 pixels."]:
      "Las imágenes deben tener un tamaño superior a 500 x 500 píxeles.",
    ["Products should occupy more than 80% of canvas."]:
      "Los productos deben ocupar más del 80% del lienzo.",
    ["Questions? View the"]: "¿Tienes preguntas? Ver el",
    ["Image Guidelines"]: "Pautas para la imagen",
    ["Help Doc"]: "Documento de ayuda",
    ["Your File is InProgress"]: "Su archivo está en proceso",
    ["Please click here to see history"]:
      "Haga clic aquí para ver el historial",
    ["Load Images From My Server"]: "Cargar imágenes desde mi servidor",
    ["If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"]:
      "Si desea agregar imágenes de joyas directamente desde el servidor como: https://www.gemfind.com/zoomimages/80785.jpg, marque esta casilla de verificación. Verifique también que haya configurado la columna IsImageFromUrl en su archivo. Si ha configurado 1 en esta columna, la imagen se cargará directamente desde el servidor y si ha configurado 0, la ruta de la imagen se tratará como simple.",
    ["Jewelry Import"]: "Importación de joyas",
    ["Add new jewelry items in bulk or make mass item updates."]:
      "Agregue nuevos artículos de joyería en forma masiva o realice actualizaciones de artículos en masa.",
    ["Map Your Content"]: "Mapee su contenido",
    ["Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."]:
      "Adapte sus categorías al estándar de la industria JewelCloud haciendo que su contenido sea compatible con nuestra red y aplicaciones.",
    ["Required if your data file is not in our format."]:
      "Obligatorio si su archivo de datos no está en nuestro formato.",
    ["Download Templates"]: "Descargar plantillas",
    ["Required Fields Form"]: "Campos obligatorios del formulario",
    ["Simple Product Forms"]: "Formularios de productos simples",
    ["Ring Builder Data Form"]: "Formulario de datos de Ring Builder",
    ["Configurable Product Form"]: "Formulario de producto configurable",
    ["Help Docs"]: "Documentos de ayuda",
    ["Image Guideline"]: "Guía de imágenes",
    ["Progress"]: "Progreso",
    ["Ok"]: "De acuerdo",
    ["Uploaded"]: "Subido",
    ["Uploading file"]: "Subiendo archivo",
    ["Create Collection"]: "Crear colección",
    ["All Collections"]: "Todas las colecciones",
    ["House Brand Collections"]: "Colecciones de marcas propias",
    ["Vendor Collections"]: "Colecciones de proveedores",
    ["Vendor Extensions"]: "Extensiones de proveedores",
    ["Collection Name"]: "Nombre de la colección",
    ["Brand Name"]: "Nombre de marca",
    ["Override"]: "Anular",
    ["Enable"]: "Permitir",
    ["Markup Override"]: "Anulación de marcado",
    ["Disable"]: "Desactivar",
    ["Percentage"]: "Porcentaje",
    ["Delete Collection"]: "Eliminar colección",
    ["Are you sure , you want to delete this Collection."]:
      "¿Estás seguro de que deseas eliminar esta colección?",
    ["content not provided."]: "contenido no proporcionado",
    ["General Settings"]: "Configuración general",
    ["Currency"]: "Divisa",
    ["Rounding"]: "Redondeo",
    ["Default Markup"]: "Marcado predeterminado",
    ["Markup"]: "Margen",
    ["Mrk"]: "Mrk",
    ["Markup By Price Range"]: "Margen por rango de precio",
    ["Markup By Size Range"]: "Margen por rango de tamaño",
    ["Min Price"]: "Precio mínimo",
    ["Max Price"]: "Precio máximo",
    ["Mark Up"]: "Aumentar",
    ["Min Size"]: "Tamaño mínimo",
    ["Max Size"]: "Tamaño máximo",
    ["Overrides"]: "Anulaciones",
    ["Save Pricing"]: "Ahorrar precios",
    ["Edit Collection"]: "Editar colección",
    ["Create A Collection"]: "Crear una colección",
    ["Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand"]:
      "Artículos que agregas para uso personal en tu tienda. Las colecciones se pueden agregar a un vendedor de JewelCloud como una extensión de su perfil oficial o de tu propia marca.",
    ["View The Training Video"]: "Ver el vídeo de formación",
    ["Collection Description"]: "Descripción de la colección",
    ["Collection Display Order"]: "Orden de visualización de la colección",
    ["Navigation Category"]: "Categoría de navegación",
    ["Bridal"]: "Nupcial",
    ["Fashion"]: "Moda",
    ["Giftware"]: "Artículos de regalo",
    ["Save Collection"]: "Guardar colección",
    ["Import Mapping"]: "Mapeo de importación",
    ["Export Mapping"]: "Mapeo de exportación",
    ["Add New Column"]: "Agregar nueva columna",
    ["Attribute"]: "Atributo",
    ["JewelCloud defined column name."]:
      "Nombre de columna definido por JewelCloud.",
    ["This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."]:
      "Esto define qué tipo de datos se permiten. Las etiquetas fijas significan que debe utilizar un valor aprobado de JewelCloud. Los valores numéricos solo pueden contener números y decimales.",
    ["Requirement"]: "Requisito",
    ["Data uploads will fail if these columns do not contain a value."]:
      "La carga de datos fallará si estas columnas no contienen un valor.",
    ["Your Attribute"]: "Tu atributo",
    ["Non Mapped"]: "No mapeado",
    ["Accepted Values"]: "Valores aceptados",
    ["Required"]: "Requerido",
    ["Example"]: "Ejemplo",
    ["Feild Type"]: "Tipo de campo",
    ["Configurable"]: "Configurable",
    ["Definition"]: "Definición",
    ["Remaining"]: "Restante",
    ["Drag & Drop Your Attributes"]: "Arrastre y suelte sus atributos",
    ["Match Your attributes with the jewel cloud industry standard"]:
      "Combine sus atributos con el estándar de la industria de la nube de joyas",
    ["Attribute Value Map"]: "Mapa de valores de atributos",
    ["Match Your Variables to the jewelCloud Industry Standard"]:
      "Adapte sus variables al estándar de la industria de jewelCloud",
    ["Your sheet just needs your column headers."]:
      "Su hoja solo necesita los encabezados de columna.",
    [".xls | .txt | .csv"]: ".xls | .txt | .csv",
    [".csv"]: ".csv",
    ["Choose Format"]: "Elija formato",
    ["No content"]: "Sin contenido",
    ["Comma"]: "Coma",
    ["Tab"]: "Pestaña",
    ["Upload Sheet"]: "Subir hoja",
    ["Import Mapping Template"]: "Importar plantilla de mapeo",
    ["Edge TPW"]: "Borde TPW",
    ["Arms"]: "Brazos",
    ["Edge EDT"]: "EDT de borde",
    ["Load File"]: "Cargar archivo",
    ["Attribute A New Mappable Column"]: "Atribuir una nueva columna asignable",
    ["Add A New Mappable Column"]: "Agregar una nueva columna asignable",
    ["Add A Single New Column Header."]:
      "Agregar un único encabezado de columna nuevo.",
    ["Column ID"]: "Identificación de columna",
    ["Column Name"]: "Nombre de la columna",
    ["Image Upload Settings"]: "Configuración de carga de imágenes",
    ["Import Multiple"]: "Importar Múltiples",
    ["True"]: "Verdadero",
    ["False"]: "FALSO",
    ["Select Delimiter"]: "Seleccionar delimitador",
    ["( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."]:
      "( |(signo de tubería), (coma) ) Utilice este delimitador único para separar las imágenes.",
    ["Add Price Type Override"]: "Agregar anulación de tipo de precio",
    ["Enforce MSRP"]: "Hacer cumplir el precio minorista sugerido",
    ["SuggestedRetail Price & Allow Retail Markups"]:
      "Precio de venta sugerido y márgenes de venta permitidos",
    ["Allow Retail Markups"]: "Permitir márgenes de beneficio minoristas",
    ["Call For Price"]: "Solicitar precio",
    ["Prices Starting at"]: "Precios desde",
    ["Allow price type override"]: "Permitir anulación del tipo de precio",
    ["Reset The Map"]: "Restablecer el mapa",
    ["Yikes...Go Back"]: "Vaya...Vuelve",
    ["Remove This Mapping"]: "Eliminar esta asignación",
    ["Are your sure you want to reset the Data Map?"]:
      "¿Está seguro de que desea restablecer el mapa de datos?",
    ["Are your sure you want to remove this mapping?"]:
      "¿Estás seguro de que deseas eliminar esta asignación?",
    ["THERE IS NO UNDO"]: "NO HAY DESHACER",
    ["Diamond Chart Reports"]: "Informes de gráficos de diamantes",
    ["Export to PDF"]: "Exportar a PDF",
    ["Diamond Clicks for GemFind (720)"]:
      "Clics de diamantes para GemFind (720)",
    ["Events"]: "Eventos",
    ["Download PDF"]: "Descargar PDF",
    ["IPAddress"]: "Dirección IP",
    ["Lab Grow Diamond"]: "Cultivar diamantes en laboratorio",
    ["Round to the nearest 1"]: "Redondear al 1 más cercano",
    ["Diamond Cut Grade Report"]: "Informe de grado de corte de diamante",
    ["Location Activity"]: "Ubicación Actividad",
    ["Types"]: "Tipos",
    ["Accept New Collection"]: "Aceptar nueva colección",
    ["If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"]:
      "Si se selecciona, recibirá nuevas colecciones de este proveedor. Si no se selecciona, solo recibirá actualizaciones de las colecciones existentes.",
    ["Retailer Affirmation"]: "Afirmación del minorista",
    ["Current Authorized Reseller"]: "Distribuidor autorizado actual",
    ["By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website."]:
      "Al marcar esta casilla y hacer clic en el botón Enviar a continuación, afirmo que GemFind tiene una cuenta abierta y activa con A & D Gem Corp, que soy un revendedor autorizado de A & D Gem Corp y que tengo permitido tener su inventario virtual en mi sitio web.",
    ["New Account Request"]: "Solicitud de nueva cuenta",
    ["I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp."]:
      "Estoy interesado en abrir una nueva cuenta con A & D Gem Corp y convertirme en revendedor autorizado. Al marcar esta casilla y hacer clic en el botón de envío a continuación, su nombre y la información de la tienda se enviarán a A & D Gem Corp.",
    ["E-Mail Address"]: "Dirección de correo electrónico",
    ["Send Invitation"]: "Enviar invitación",
    ["Last Requested"]: "Última solicitud",
    ["SKU"]: "SKU",
    ["InHouse"]: "En casa",
    ["Measurement"]: "Medición",
    ["Cert"]: "Certificado",
    ["Mined"]: "Minado",
    ["Princess"]: "Princesa",
    ["Pear"]: "Pera",
    ["Marquise"]: "Marquesa",
    ["Ideal"]: "Ideal",
    ["Excellent"]: "Excelente",
    ["Very Good"]: "Muy bien",
    ["Good"]: "Bien",
    ["Fair"]: "Justo",
    ["Diamond Preview"]: "Vista previa del diamante",
    ["Sample Image"]: "Imagen de muestra",
    ["Stock Number"]: "Número de stock",
    ["Add To Compare"]: "Añadir para comparar",
    ["SIMILAR DIAMONDS"]: "DIAMANTES SIMILARES",
    ["Compare"]: "Comparar",
    ["Fancy Colored"]: "Color de fantasía",
    ["D"]: "D",
    ["E"]: "mi",
    ["F"]: "F",
    ["G"]: "GRAMO",
    ["I"]: "I",
    ["J"]: "Yo",
    ["K"]: "K",
    ["L"]: "yo",
    ["M"]: "METRO",
    ["N"]: "norte",
    ["O"]: "Oh",
    ["P"]: "PAG",
    ["Lab Growm"]: "Cultivo de laboratorio",
    ["No Diamonds Listed In That Range"]: "No hay diamantes en ese rango",
    ["Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs."]:
      "Por favor, introduzca sus datos de contacto. Uno de nuestros especialistas en diamantes se pondrá en contacto con usted y le ayudará con sus necesidades.",
    ["First Name"]: "Nombre de pila",
    ["Last Name"]: "Apellido",
    ["Add Comments Here"]: "Añade comentarios aquí",
    ["Request"]: "Pedido",
    ["Please enter the details of the type of diamond you are looking for and your contact information."]:
      "Ingrese los detalles del tipo de diamante que está buscando y su información de contacto.",
    ["Your information will be submitted to one of our diamond specialists that will contact you."]:
      "Su información será enviada a uno de nuestros especialistas en diamantes que se pondrá en contacto con usted.",
    ["Advance"]: "Avance",
    ["Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"]:
      "Utilice su comparación de diamantes para guardar todos los detalles de los diamantes que está considerando y verlos todos juntos",
    ["This makes it easy to choose the right diamond."]:
      "Esto hace que sea fácil elegir el diamante adecuado.",
    ["Mined Diamonds"]: "Diamantes extraídos",
    ["Lab Grown"]: "Cultivado en laboratorio",
    ["Color Diamonds"]: "Diamantes de colores",
    ["ID"]: "IDENTIFICACIÓN",
    ["Certs"]: "Certificados",
    ["Method"]: "Método",
    ["Custom Method"]: "Método personalizado",
    ["In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"]:
      "Para proteger la integridad de los diamantes y la industria de la joyería, el margen mínimo en el enlace de diamantes no debe ser inferior al 7 %.",
    ["Markup By Vendor"]: "Margen por proveedor",
    ["Markup By PriceRange With Certs"]:
      "Margen por rango de precio con certificados",
    ["Markup By SizeRange With Certs"]:
      "Marcado por rango de tamaño con certificados",
    ["CL"]: "CL",
    ["Update Markup"]: "Actualizar marcado",
    ["GIA"]: "GIA",
    ["AGS"]: "AGS",
    ["EGL"]: "EGL",
    ["IGI"]: "IGI",
    ["Enable Diamonds with"]: "Habilitar diamantes con",
    ["Video Only"]: "Solo video",
    ["Enable Dynamic Range"]: "Habilitar rango dinámico",
    ["EGL Belgium"]: "EGL Bélgica",
    ["EGL International"]: "EGL Internacional",
    ["EGL Israel"]: "EGL Israel",
    ["EGL South Africa"]: "EGL Sudáfrica",
    ["EGL USA"]: "EGL Estados Unidos",
    ["HRD"]: "Desarrollo de recursos humanos",
    ["GCAL"]: "GCAL",
    ["None"]: "Ninguno",
    ["FNT"]: "FNT",
    ["MED"]: "MEDICINA",
    ["ST"]: "CALLE",
    ["ALL"]: "TODO",
    ["Configurable Attribute"]: "Atributo configurable",
    ["Shipping Charge"]: "Gastos de envío",
    ["Update Settings"]: "Actualizar configuración",
    ["Standard Search"]: "Búsqueda estándar",
    ["Advanced Search"]: "Búsqueda avanzada",
    ["Fancy Colored Search"]: "Búsqueda de colores elegantes",
    ["Lab Grown Search"]: "Búsqueda de productos cultivados en laboratorio",
    ["Request a Diamond"]: "Solicitar un diamante",
    ["Compare Diamonds"]: "Comparar diamantes",
    ["Left Navigation for MasterLink"]: "Navegación izquierda para MasterLink",
    ["Loose Diamonds"]: "Diamantes sueltos",
    ["Same Window"]: "La misma ventana",
    ["New Window"]: "Nueva ventana",
    ["Inframe"]: "Enmarcado",
    ["Our Signature Diamonds"]: "Nuestros diamantes exclusivos",
    ["Best Value Diamonds"]: "Diamantes de mejor valor",
    ["Private Reserve Diamonds"]: "Diamantes de reserva privada",
    ["HeartStar Diamonds"]: "Diamantes HeartStar",
    ["Change this value to set the TITLE at the top of the search results page. Leave blank for default value."]:
      "Cambie este valor para establecer el TÍTULO en la parte superior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado.",
    ["Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value."]:
      "Cambie este valor para establecer el TÍTULO en la parte superior de la página de detalles del diamante. Déjelo en blanco para el valor predeterminado.",
    ["Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace n.° 1 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL del enlace n.° 1 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace n.° 2 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL del enlace n.° 2 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer el TEXTO del enlace n.° 3 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "Cambie este valor para establecer la URL del enlace n.° 3 en la parte inferior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only)."]:
      "Cambie este valor para permitir que los usuarios de GFLink busquen pares de piedras coincidentes además de piedras individuales (también TODAS las piedras). Déjelo en blanco para el valor predeterminado (solo piedras individuales).",
    ["Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page."]:
      "Utilice esta opción para habilitar (Sí) o deshabilitar (No) la visualización del logotipo (es decir, la URL de la IMAGEN DE LA PÁGINA DE RESULTADOS) en la parte superior de la página de resultados de búsqueda.",
    ["Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Cambie este valor para modificar la URL asociada con la imagen que se muestra en la parte superior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno). Si envía su imagen a GemFind para colocarla en un servidor de GemFind, puede colocar únicamente el nombre del archivo.",
    ["Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none)."]:
      "Cambie este valor para modificar el hipervínculo asociado con la imagen que se muestra en la parte superior de la página de resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "Cambie este valor para modificar la URL asociada con la imagen que se muestra en el medio de la página de detalles del diamante predeterminada. Déjelo en blanco para el valor predeterminado (ninguno). Si envía su imagen a GemFind para colocarla en un servidor de GemFind, puede colocar solo el nombre del archivo.",
    ["Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value."]:
      "Cambie este valor para modificar el texto visible del hipervínculo a los detalles del diamante. Déjelo en blanco para el valor predeterminado.",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      'De manera predeterminada, la búsqueda mostrará los detalles básicos del diamante (talla, color, claridad, tamaño, precio y certificado) en la página de resultados de búsqueda. Puede optar por mostrar información más detallada (tabla, profundidad, medidas) además de la información básica en la página de resultados de búsqueda. Independientemente del método que seleccione, los detalles completos de cada diamante siempre estarán disponibles haciendo clic en "Más información" en la página de resultados de búsqueda.',
    ["You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "Si lo desea, puede optar por buscar por grado de corte. La búsqueda por tabla y profundidad también está habilitada, ya que están directamente relacionadas con el grado de corte.",
    ["Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "Seleccione la cantidad de diamantes que se muestran en cada página de los resultados de búsqueda. Para valores distintos a los que se muestran, debe comunicarse directamente con GemFind.",
    ["Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form."]:
      "Elija si desea mostrar cortes de diamantes más oscuros (Asscher, Old Miner) en el formulario de búsqueda.",
    ["Choose whether to use the interactive Macromedia Flash search form instead of the regular search form."]:
      "Elija si desea utilizar el formulario de búsqueda interactivo de Macromedia Flash en lugar del formulario de búsqueda normal.",
    ["Choose whether to use show popup of diamond details on the diamond inveotry page."]:
      "Elija si desea utilizar la ventana emergente para mostrar los detalles del diamante en la página de inventario de diamantes.",
    ["Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page."]:
      "Elija si desea mostrar el botón para compartir (Face Book, Twitter, Digg, etc.) en la página de detalles del diamante.",
    ["Choose whether to link diamond search with new ring builder."]:
      "Elija si desea vincular la búsqueda de diamantes con el nuevo generador de anillos.",
    ["Choose whether to display Ring Builder image at the right column of new diamond link."]:
      "Elija si desea mostrar la imagen de Ring Builder en la columna derecha del nuevo enlace de diamante.",
    ["Search Page Title"]: "Título de la página de búsqueda",
    ["Diamond Detail page Title"]:
      "Título de la página de detalles del diamante",
    ["Hyperlink #1 TEXT at bottom of search results page"]:
      "Hipervínculo n.° 1 TEXTO en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #1 URL at bottom"]:
      "Hipervínculo n.° 1 URL en la parte inferior",
    ["Hyperlink #2 TEXT at bottom"]:
      "Hipervínculo #2 TEXTO en la parte inferior",
    ["Hyperlink #2 URL at bottomof search results page"]:
      "Hipervínculo n.° 2 URL en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #3 TEXT at bottom of search results page"]:
      "Hipervínculo n.° 3 TEXTO en la parte inferior de la página de resultados de búsqueda",
    ["Hyperlink #3 URL at bottom of search results page"]:
      "Hipervínculo n.° 3 URL en la parte inferior de la página de resultados de búsqueda",
    ["Let users search for MATCHED PAIRS"]:
      "Permitir que los usuarios busquen PARES COINCIDENTES",
    ["Show Logo on Results Page"]:
      "Mostrar logotipo en la página de resultados",
    ["Show Diamond Education on Results Page"]:
      "Mostrar educación sobre diamantes en la página de resultados",
    ["Results page image URL"]: "URL de la imagen de la página de resultados",
    ["Results page image hyperlink URL"]:
      "URL de hipervínculo de imagen de la página de resultados",
    ["Diamond details page image URL"]:
      "URL de la imagen de la página de detalles del diamante",
    ["Alternate diamond details link text"]:
      "Texto del enlace con detalles de diamantes alternativos",
    ["Enhanced details in search results"]:
      "Detalles mejorados en los resultados de búsqueda",
    ["Search By Cut Grade"]: "Búsqueda por grado de corte",
    ["Number of diamonds displayed per page"]:
      "Número de diamantes mostrados por página",
    ["Show odd diamond cuts on search form"]:
      "Mostrar cortes de diamantes extraños en el formulario de búsqueda",
    ["Dynamic Flash search form"]: "Formulario de búsqueda dinámica en Flash",
    ["Show popup on Diamond inventory display page"]:
      "Mostrar ventana emergente en la página de visualización del inventario de diamantes",
    ["Show Share Button on Details Page (for New GF Link only)"]:
      "Mostrar el botón Compartir en la página de detalles (solo para el enlace de New GF)",
    ["Use New Ringbuilder"]: "Utilice el nuevo Ringbuilder",
    ["Show Ring Builder Image (for New GF Link only)"]:
      "Mostrar imagen de Ring Builder (solo para New GF Link)",
    ["Generate API Key to get data"]: "Generar clave API para obtener datos",
    ["Generate"]: "Generar",
    ["API Documentation"]: "Documentación API",
    ["Markup="]: "Marcado=",
    ["Markups"]: "Márgenes de beneficio",
    ["Setting #"]: "Configuración #",
    ["Vendor #"]: "Proveedor #",
    ["Metal"]: "Metal",
    ["$ Price"]: "$ Precio",
    ["$ Markup"]: "$ Margen",
    ["Setup"]: "Configuración",
    ["Ring Builder URL"]: "URL del generador de anillos",
    ["Enter the URL of this tool on your site to enable social sharing and dynamic sizing."]:
      "Ingrese la URL de esta herramienta en su sitio para habilitar el uso compartido social y el tamaño dinámico.",
    ["Internal Use Link"]: "Enlace de uso interno",
    ["Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."]:
      "Seleccione Clima para mostrar el enlace “Solo para uso interno” en la parte inferior de la página Detalles de configuración, que le permitirá buscar el número de stock del proveedor y la información de contacto para la configuración visualizada.",
    ["Display"]: "Mostrar",
    ["RingSize Type"]: "Tipo de tamaño de anillo",
    ["Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."]:
      "Elija el formato de tamaño de anillo que desea mostrar (americano: 1, 1,5, 2, etc. o británico: A, A.5, B, B.5, etc.).",
    ["American"]: "Americano",
    ["British"]: "británico",
    ["Roman"]: "romano",
    ["Europe"]: "Europa",
    ["Diamond Initially Sort Result On"]:
      "Resultado de la clasificación inicial del diamante",
    ["Select a diamond attribute that you would like to initially sort the diamonds by."]:
      "Seleccione un atributo de diamante por el cual desea ordenar inicialmente los diamantes.",
    ["Ascending"]: "Ascendente",
    ["Descending"]: "Descendiendo",
    ["Default Metal Type on Setting page"]:
      "Tipo de metal predeterminado en la página de configuración",
    ["14K White Gold"]: "Oro blanco de 14 quilates",
    ["14K Yellow Gold"]: "Oro amarillo de 14 quilates",
    ["Show Advanced Search Options By Default"]:
      "Mostrar opciones de búsqueda avanzada de forma predeterminada",
    ["Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Elija si desea mostrar el filtrado opcional avanzado de forma predeterminada (Polaco, Simetría, Fluorescencia, Certificados, etc.).",
    ["Rings - Show Schedule A Viewing"]:
      "Anillos - Mostrar horario A Visualización",
    ["Choose Whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Elija si desea mostrar el enlace que permite al cliente programar una cita.",
    ["Show Addresses"]: "Mostrar direcciones",
    ["Choose Whether to display your company address and contact information within the RingBuilder."]:
      "Elija si desea mostrar la dirección de su empresa y la información de contacto dentro de RingBuilder.",
    ["Show Center Stone Size"]: "Mostrar tamaño de piedra central",
    ["Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."]:
      "Advertencia: Deshabilitar esta opción eliminará la restricción de rango de quilates para los engastes de anillos y puede provocar un aumento en los precios de costo con respecto a lo establecido para el engaste de anillos seleccionado. Para obtener más información, consulte Montajes nocturnos.",
    ["Show Price"]: "Mostrar precio",
    ["Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."]:
      "Elija si desea mostrar los precios de los anillos o no. Si elige NO, se mostrará en su lugar “Consultar precio”.",
    ["Apply GST Tax"]: "Aplicar el impuesto GST",
    ["Toggle the ability to apply international tax."]:
      "Activar o desactivar la posibilidad de aplicar impuestos internacionales.",
    ["GST Tax Value"]: "Valor del impuesto GST",
    ["Set the value when applying the GST Tax option."]:
      "Establezca el valor al aplicar la opción de impuesto GST.",
    ["Social"]: "Social",
    ["Show Pinterest Share"]: "Mostrar Pinterest Compartir",
    ["Choose whether to display the Pinterest “Pin it” button or not."]:
      "Elija si desea mostrar el botón “Pin it” de Pinterest o no.",
    ["Show Twitter Share"]: "Mostrar Twitter Compartir",
    ["Choose whether to display the Twitter “Tweet” button or not."]:
      "Elija si desea mostrar el botón “Tweet” de Twitter o no.",
    ["Show Facebook Share"]: "Mostrar Facebook Compartir",
    ["Choose whether to display the Facebook “Share” button or not."]:
      "Elija si desea mostrar el botón “Compartir” de Facebook o no.",
    ["Show Facebook Like"]: "Mostrar Me gusta en Facebook",
    ["Choose whether to display the Facebook “Like” button or not."]:
      "Elige si quieres mostrar el botón “Me gusta” de Facebook o no.",
    ["Show Google Plus"]: "Mostrar Google Plus",
    ["Choose whether to display the Google “G+1” button or not."]:
      "Elija si desea mostrar el botón “G+1” de Google o no.",
    ["Choose whether to display the Instagram “Share” button or not."]:
      "Elige si quieres mostrar el botón “Compartir” de Instagram o no.",
    ["E-Commerce"]: "Comercio electrónico",
    ["Show Add To Cart Button"]: "Mostrar botón Agregar al carrito",
    ["Alternate Shopping Cart"]: "Carrito de compra alternativo",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Cambie este valor para modificar el hipervínculo real al carrito de compras. Puede cambiarlo para que vincule a su propia página del carrito de compras. Cuando este valor está presente, se pasan parámetros adicionales de diamantes y detalles de configuración en la URL. Déjelo en blanco para el valor predeterminado.",
    ["Show Buy Setting Only"]: "Mostrar solo configuración de compra",
    ["Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."]:
      "Seleccione si desea que se muestre o no el botón “Comprar solo el engaste”. Esto permite que el cliente utilice RingBuilder para comprar solo el engaste del anillo en lugar del anillo completo con diamantes.",
    ["Show Buy Diamond Only"]: "Mostrar Comprar Solo Diamante",
    ["Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."]:
      "Seleccione si desea que se muestre o no el botón “Comprar solo diamantes”. Esto permite que el cliente utilice RingBuilder para comprar solo el diamante en lugar del anillo terminado.",
    ["View Cart"]: "Ver carrito",
    ["Button"]: "Botón",
    ["Choose Whether to display the PayPal shopping cart's view button or Not"]:
      "Elija si desea mostrar el botón de vista del carrito de compras de PayPal o no",
    ["No content."]: "Sin contenido.",
    ["Page Alignment"]: "Alineación de página",
    ["Center"]: "Centro",
    ["Left"]: "Izquierda",
    ["Right"]: "Bien",
    ["Show Social Sharing Buttons"]:
      "Mostrar botones para compartir en redes sociales",
    ["Show View In Store"]: "Mostrar Ver en tienda",
    ["Show Pop Up On Diamond Search"]:
      "Mostrar ventana emergente en la búsqueda de diamantes",
    ["Diamond Search Result Page Size"]:
      "Tamaño de la página de resultados de búsqueda de diamantes",
    ["Rings - Search Result Page Size"]:
      "Anillos - Página de resultados de búsqueda Tamaño",
    ["Rings - Initially Sort Result On"]:
      "Anillos - Ordenar inicialmente el resultado",
    ["Setting Number"]: "Número de configuración",
    ["Send Email To Friend"]: "Enviar correo electrónico a un amigo",
    ["Send Email To Retailer"]: "Enviar correo electrónico al minorista",
    ["Send Email To Sender"]: "Enviar correo electrónico al remitente",
    ["Request A Diamond Email"]: "Solicitar un diamante por correo electrónico",
    ["Request A Diamond"]: "Solicitar un diamante",
    ["Send Mail to Request A Diamond"]:
      "Enviar correo para solicitar un diamante",
    ["Wishlist"]: "Lista de deseos",
    ["Bridal Registry"]: "Registro nupcial",
    ["Shopping Cart"]: "Carro de la compra",
    ["Show Store Hours"]: "Mostrar horario de la tienda",
    ["Website URL"]: "URL del sitio web",
    ["Google Analytic ID"]: "Identificación de Google Analytics",
    ["Show Only MSRP Prices"]: "Mostrar solo precios MSRP",
    ["Masterlink Theme"]: "Tema de Masterlink",
    ["Show View"]: "Mostrar vista",
    ["New MasterLink Use In Iframe?"]: "¿Nuevo uso de MasterLink en Iframe?",
    ["Login URL"]: "URL de inicio de sesión",
    ["Show WholeSale Price Internal Use Link?"]:
      "Mostrar precio al por mayor ¿Enlace de uso interno?",
    ["Show Address/Phone Details?"]: "¿Mostrar detalles de dirección/teléfono?",
    ["Diamond Search / Ringbuilder load from Masterlink?"]:
      "Búsqueda de diamantes / ¿Carga de Ringbuilder desde Masterlink?",
    ["Diamond Image URL"]: "URL de la imagen de diamante",
    ["Vendor Details open or closed by default in new masterlink"]:
      "Los detalles del proveedor se abren o cierran de forma predeterminada en el nuevo enlace maestro",
    ["Show Additional PopUp in new masterlink"]:
      "Mostrar ventana emergente adicional en el nuevo enlace maestro",
    ["Shopping Cart page message"]:
      "Mensaje de la página del carrito de compras",
    ["Hearts On Fire Link"]: "Enlace de Corazones en llamas",
    ["Show Setting Name at Setting Grid"]:
      "Mostrar el nombre de la configuración en la cuadrícula de configuración",
    ["Show Ring Builder with One Step"]:
      "Constructor de ring de exhibición en un solo paso",
    ["Show Default Ring Size"]: "Mostrar tamaño de anillo predeterminado",
    ["Shop Diamonds"]: "Comprar diamantes",
    ["Shop Bridal & Engagement"]: "Tienda de novias y compromisos",
    ["Shop Designers"]: "Diseñadores de tiendas",
    ["Shop Jewelry"]: "Tienda de joyas",
    ["Shop Watches"]: "Comprar relojes",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."]:
      "Elija si desea mostrar el enlace “Solo para uso interno” en la parte inferior de la página Detalles del diamante, que le permitirá buscar el número de stock del proveedor y la información de contacto para el diamante visualizado.",
    ["Sticky Header Height"]: "Altura del encabezado fijo",
    ["Enter a Sticky Header Height."]:
      "Introduzca una altura para el encabezado fijo.",
    ["Platinum Education"]: "Educación Platino",
    ["Provides a link to copy for platinum education in Ring Builder."]:
      "Proporciona un enlace para copiar para la educación sobre platino en Ring Builder.",
    ["Ring Size Guide URL"]: "URL de la guía de tallas de anillos",
    ["Provides a link to copy for ring sizing guides provided by GemFind."]:
      "Proporciona un enlace para copiar las guías de tamaño de anillos proporcionadas por GemFind.",
    ["Show Breadcrumbs"]: "Mostrar migas de pan",
    ["Shows navigation path a customer took to get to page they're on."]:
      "Muestra la ruta de navegación que tomó un cliente para llegar a la página en la que se encuentra.",
    ["Create Profile"]: "Crear perfil",
    ["text"]: "texto",
    ["Provide alternate text for user registration Button."]:
      "Proporcionar texto alternativo para el botón de registro de usuario.",
    ["Sort Products by Price Initially By"]:
      "Ordenar productos por precio inicialmente por",
    ["Allow Customers to Sort Product By Price."]:
      "Permitir a los clientes ordenar el producto por precio.",
    ["Price: Low - High"]: "Precio: Bajo - Alto",
    ["Price: High - Low"]: "Precio: Alto - Bajo",
    ["Collection Filtering"]: "Filtrado de colecciones",
    ["When navigating Designer Landing pages - show collections instead of categories."]:
      "Al navegar por las páginas de destino de Designer, muestre colecciones en lugar de categorías.",
    ["Schedule Viewing"]: "Programar visualización",
    ["Allow Customers to schedule a meeting with you."]:
      "Permitir que los clientes programen una reunión con usted.",
    ["Designer Tile Layout"]: "Diseño de mosaicos",
    ["Show Back to Top Button."]: "Mostrar el botón Volver al inicio.",
    ["Enabled Back To Top"]: "Habilitado Volver arriba",
    ["Allow address to show on detailpage"]:
      "Permitir que la dirección se muestre en la página de detalles",
    ["Text"]: "Texto",
    ["(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."]:
      '(Cuando se desactivan los precios, de manera predeterminada, se mostrará "Solicitar precio". Esta opción le permite reemplazar "Solicitar precio" con un texto alternativo (por ejemplo, "Solicitar disponibilidad").',
    ["Starting At Price"]: "A partir del precio",
    ["Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."]:
      "Proporcione un texto de prefijo (incluirá el precio) cuando un producto use el Tipo de precio 5 (los productos con el Tipo de precio “A partir de” (5) no se pueden agregar al carrito de compras).",
    ["instead of the Price"]: "En lugar del precio",
    ["Hides the retail price for products using Price Type 1,2,or 3 ."]:
      "Oculta el precio minorista de los productos que utilizan el tipo de precio 1, 2 o 3.",
    ["Facebook App ID"]: "Identificación de la aplicación de Facebook",
    ["Connect MasterLink to your Facebook page, using your Facebook App ID."]:
      "Conecte MasterLink a su página de Facebook, usando su ID de aplicación de Facebook.",
    ["Facebook Like Page URL"]: "URL de la página de Me gusta de Facebook",
    ["Allow Like access to your facebook account by providing the URL."]:
      "Permita que Me gusta acceda a su cuenta de Facebook proporcionándole la URL.",
    ["Allow Customers to post your products to their Pinterest boards."]:
      "Permita que los clientes publiquen sus productos en sus tableros de Pinterest.",
    ["Allow Customers to share your products to their Twitter account."]:
      "Permita que los clientes compartan sus productos en su cuenta de Twitter.",
    ["Allow Customers to share your products to their Facebook page."]:
      "Permita que los clientes compartan sus productos en su página de Facebook.",
    ["Allow Customers to like your products directly from your website."]:
      "Permita que los clientes den me gusta a sus productos directamente desde su sitio web.",
    ["SEO"]: "Posicionamiento en buscadores (SEO)",
    ["Masterlink Default Title"]: "Título predeterminado de Masterlink",
    ["Enter a title for search engine optimization."]:
      "Introduzca un título para la optimización del motor de búsqueda.",
    ["Masterlink Meta Keywords"]: "Palabras clave meta de Masterlink",
    ["Enter a Keywords for search engine optimization."]:
      "Introduzca una palabra clave para la optimización del motor de búsqueda.",
    ["Masterlink Meta Description"]: "Meta descripción de Masterlink",
    ["Enter a description for search engine optimization."]:
      "Ingrese una descripción para la optimización del motor de búsqueda.",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."]:
      "Cambie este valor para modificar el hipervínculo real al carrito de compras. Puede cambiarlo para que incluya un vínculo a su propia página del carrito de compras.",
    ["Enable the View Cart button for customers to review their products selections."]:
      "Habilite el botón Ver carrito para que los clientes revisen sus selecciones de productos.",
    ["New Favourite List"]: "Nueva lista de favoritos",
    ["New Favourite Items Added"]: "Nuevos artículos favoritos añadidos",
    ["Create Your Own Diamond Studs in 3 easy steps with Stud Builder"]:
      "Crea tus propios pendientes de diamantes en 3 sencillos pasos con Stud Builder",
    ["Select Your Diamonds"]: "Seleccione sus diamantes",
    ["Choose Your Mounting"]: "Elige tu montaje",
    ["Complete Your Earrings"]: "Completa tus pendientes",
    ["Standard"]: "Estándar",
    ["Add To Stud"]: "Añadir al Stud",
    ["Advanced"]: "Avanzado",
    ["Diamonds Markup"]: "Margen de diamantes",
    ["Diamond Drop A Hint"]: "El diamante deja una pista",
    ["Stud Builder URL"]: "URL del constructor de montantes",
    ["Internal Use Link?"]: "¿Enlace de uso interno?",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."]:
      "Elija si desea mostrar el enlace “Solo para uso interno” en la parte inferior de la página Detalles del artículo, que le permitirá buscar el número de stock del proveedor y la información de contacto para el par de diamantes visualizado.",
    ["Only Diamond Search"]: "Solo busqueda de diamantes",
    ["Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."]:
      "Elija si desea mostrar SÓLO la parte de Búsqueda de diamantes de Stud Builder, que combina diamantes con atributos similares.",
    ["Show Dealer SKU In Diamond Search"]:
      "Mostrar SKU del distribuidor en Búsqueda de diamantes",
    ["Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."]:
      "Elija si desea mostrar el número de stock del distribuidor/proveedor en lugar del número de SKU único predeterminado de GemFind (por ejemplo, SKU n.° 108545370).",
    ["Choose whether to display the link which allows the customer the ability to schedule an appointment."]:
      "Elija si desea mostrar el enlace que permite al cliente programar una cita.",
    ["Allow address to show on detailpage."]:
      "Permitir que la dirección se muestre en la página de detalles.",
    ["Choose whether to display “Call for Price” instead of the retail price or not."]:
      "Elija si desea mostrar “Solicitar precio” en lugar del precio minorista o no.",
    ["Shopping Cart Url"]: "URL del carrito de compras",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Cambie este valor para modificar el hipervínculo real al carrito de compras. Puede cambiarlo para que vincule a su propia página del carrito de compras. Cuando este valor está presente, se pasan parámetros de detalles de diamantes adicionales en la URL. Déjelo en blanco para el valor predeterminado.",
    ["Choose whether to display the “Add to Cart” button or not."]:
      "Elija si desea mostrar el botón “Añadir al carrito” o no.",
    ["Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."]:
      'Elija si desea mostrar el botón "Ver carrito" del carrito de compras de PayPal o no.',
    ["Generate Sync"]: "Generar sincronización",
    ["Generate Full Sync"]: "Generar sincronización completa",
    ["File Name"]: "Nombre del archivo",
    ["Folder"]: "Carpeta",
    ["Enter FTP Detail for SYNC File Below"]:
      "Introduzca los detalles del FTP para el archivo SYNC a continuación",
    ["User Id"]: "Id. de usuario",
    ["Is SSL?"]: "¿Es SSL?",
    ["Configure & Customize Your"]: "Configura y personaliza tu",
    ["Pre-Owned Rolex Watch"]: "Reloj Rolex de segunda mano",
    ["E-Mail A Friend"]: "Enviar correo electrónico a un amigo",
    ["Print Details"]: "Detalles de impresión",
    ["Have a question regarding this item? Our specialist are available to assist you. | Call us:"]:
      "¿Tiene alguna pregunta sobre este artículo? Nuestros especialistas están disponibles para ayudarlo. | Llámenos:",
    ["Items Details"]: "Detalles de los artículos",
    ["STEP 1 | Size & Bracelet Type"]: "PASO 1 | Tamaño y tipo de pulsera",
    ["Choose Watch Type"]: "Elija el tipo de reloj",
    ["Men's"]: "de los hombres",
    ["Ladies"]: "Señoras",
    ["Mid Size"]: "Tamaño mediano",
    ["Choose Bracelet Style"]: "Elija el estilo de pulsera",
    ["Jubilee"]: "Aniversario",
    ["Oyster"]: "Ostra",
    ["President"]: "Presidente",
    ["Choose Bracelet Metal"]: "Elige el metal de la pulsera",
    ["Stainless Steel"]: "Acero inoxidable",
    ["18K Y/SS"]: "18K Y/S",
    ["18K Yellow"]: "Amarillo 18K",
    ["18K White"]: "Blanco 18K",
    ["STEP 2 | Bezel Type"]: "PASO 2 | Tipo de bisel",
    ["Choose Bezel Type"]: "Elija el tipo de bisel",
    ["Choose Bezel Metal"]: "Elija el metal del bisel",
    ["Choose Bezel Gemstone Type"]:
      "Elija el tipo de piedra preciosa del bisel",
    ["Choose Bezel Gemstone Shape"]:
      "Elija la forma de la piedra preciosa del bisel",
    ["Choose Bezel Diamond Quality"]: "Elija la calidad del diamante del bisel",
    ["STEP 3 | Dial Type"]: "PASO 3 | Tipo de marcación",
    ["Choose Dial Type"]: "Elija el tipo de marcación",
    ["Marker Type"]: "Tipo de marcador",
    ["Custom Dial Colors"]: "Colores de esfera personalizados",
    ["Specifications"]: "Presupuesto",
    ["Size & Bracelet Type (STEP 1)"]: "Tamaño y tipo de pulsera (PASO 1)",
    ["SKU#"]: "Código SKU",
    ["Watch Type"]: "Tipo de reloj",
    ["Men"]: "Hombres",
    ["Bracelet Style"]: "Estilo de pulsera",
    ["Bracelet Metal"]: "Pulsera de metal",
    ["Bezel Type (STEP 2)"]: "Tipo de bisel (PASO 2)",
    ["Custom Bezel Type"]: "Tipo de bisel personalizado",
    ["Custom Bezel Metal"]: "Bisel de metal personalizado",
    ["Custom Bezel Gemstones"]: "Piedras preciosas con bisel personalizado",
    ["Custom Bezel Gemstone Shape"]:
      "Forma de piedra preciosa con bisel personalizado",
    ["Custom Bezel Diamond Quality"]:
      "Bisel personalizado con calidad de diamante",
    ["Total Carat Weight"]: "Peso total en quilates",
    ["Dial Type (STEP 3)"]: "Tipo de marcación (PASO 3)",
    ["Dial Type"]: "Tipo de dial",
    ["Custom Dial Color"]: "Color de esfera personalizado",
    ["Your Name"]: "Su nombre",
    ["Your E-Mail Address"]: "Su dirección de correo electrónico",
    ["Your Company Name"]: "El nombre de su empresa",
    ["Your Phone Number"]: "Tu número de teléfono",
    ["Your Store Phone Number"]: "El número de teléfono de su tienda",
    ["Write A Personal Message"]: "Escribe un mensaje personal",
    ["Hint Recipient's Name"]: "Nombre del destinatario de la sugerencia",
    ["Hint Recipient's E-Mail"]:
      "Correo electrónico del destinatario de la sugerencia",
    ["Reason For This Gift"]: "Motivo de este regalo",
    ["Gift Deadline"]: "Fecha límite para donaciones",
    ["Add A Personal Message Here"]: "Añade un mensaje personal aquí",
    ["Friend E-Mail Address"]: "Dirección de correo electrónico del amigo",
    ["Subject"]: "Sujeto",
    ["Schedule"]: "Cronograma",
    ["Your Email"]: "Tu correo electrónico",
    ["Appointment Date"]: "Fecha de cita",
    ["Add A Message Here"]: "Añade un mensaje aquí",
    ["Get Directions"]: "Obtener direcciones",
    ["Watch Customizer Options"]: "Opciones de personalización del reloj",
    ["Watch Customizer URL"]: "Ver URL del personalizador",
    ["Update Setting"]: "Actualizar configuración",
    ["Symetry"]: "Simetría",
    ["Choose Your Setting"]: "Elija su configuración",
    ["Complete Your Pendant"]: "Completa tu colgante",
    ["Add To Your Pendant"]: "Añade a tu colgante",
    ["Pendant Builder URL"]: "URL del generador de colgantes",
    ["Enter a URL."]: "Introduzca una URL.",
    ["Show Advanced Search As Default"]:
      "Mostrar búsqueda avanzada como predeterminada",
    ["Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "Elija si desea mostrar el filtrado opcional avanzado de forma predeterminada (Polaco, Simetría, Fluorescencia, Certificados, etc.)",
    ["Choose whether to display the “View Cart” button or not."]:
      "Elija si desea mostrar el botón “Ver carrito” o no.",
    ["Total items"]: "Total de artículos",
    ["Total Cost"]: "Costo total",
    ["Purchase order date"]: "Fecha de orden de compra",
    ["Order Confirm Date"]: "Fecha de confirmación del pedido",
    ["Sale Per"]: "Venta por",
    ["Confirm"]: "Confirmar",
    ["Quantity"]: "Cantidad",
    ["Order Date"]: "Fecha de pedido",
    ["Folder Path"]: "Ruta de la carpeta",
    ["Choose File To Upload"]: "Seleccione el archivo que desea cargar",
    ["Filter By Trade Show"]: "Filtrar por feria comercial",
    ["Trade Show 1"]: "Feria comercial 1",
    ["Trade Show 2"]: "Feria comercial 2",
    ["Trade Show 3"]: "Feria comercial 3",
    ["Buyer"]: "Comprador",
    ["Are you sure to update Permission?"]:
      "¿Estás seguro de actualizar el permiso?",
    ["Report Permission Control"]: "Control de permisos de informes",
    ["Update Date on which"]: "Fecha de actualización en la que",
    ["Is Created"]: "Se crea",
    ["Select Vendor Status"]: "Seleccionar estado del proveedor",
    ["Select Status"]: "Seleccionar estado",
    ["Company name is compulsory"]: "El nombre de la empresa es obligatorio",
    ["Street address is compulsory"]: "La dirección de la calle es obligatoria",
    ["City is compulsory"]: "La ciudad es obligatoria",
    ["City is invalid"]: "La ciudad no es válida",
    ["State is compulsory"]: "El estado es obligatorio",
    ["State is invalid"]: "El estado no es válido",
    ["Zip code is compulsory"]: "El código postal es obligatorio",
    ["Zip code is invalid"]: "El código postal no es válido",
    ["Kindly select the country"]: "Por favor seleccione el país",
    ["Contact name is compulsory"]: "El nombre de contacto es obligatorio",
    ["Email address is compulsory"]:
      "La dirección de correo electrónico es obligatoria",
    ["Email address is invalid"]:
      "La dirección de correo electrónico no es válida",
    ["Phone number is compulsory"]: "El número de teléfono es obligatorio",
    ["Phone number is invalid"]: "El número de teléfono no es válido",
    ["Username is compulsory"]: "El nombre de usuario es obligatorio",
    ["Password is compulsory"]: "La contraseña es obligatoria",
    ["Password should have a spe"]: "La contraseña debe tener una",
    ["Kindly select the permission type"]:
      "Por favor seleccione el tipo de permiso",
    ["Kindly select the retailer status"]:
      "Por favor seleccione el estado del minorista",
    ["Kindly select the vendor status"]:
      "Por favor seleccione el estado del proveedor",
    ["RetailerCore 1"]: "Minorista Core 1",
    ["RetailerCore 2"]: "Minorista Core 2",
    ["RetailerCore 3"]: "Minorista Core 3",
    ["RetailerCore 4"]: "Minorista Core 4",
    ["RetailerCore 5"]: "Minorista Core 5",
    ["RetailerCore 6"]: "Minorista Core 6",
    ["RetailerCore 7"]: "Minorista Core 7",
    ["RetailerCore 8"]: "Minorista Core 8",
    ["RetailerCore 9"]: "Minorista Core 9",
    ["RetailerCore 10"]: "Minorista Core 10",
    ["RetailerCore 11"]: "Minorista Core 11",
    ["RetailerCore 12"]: "Minorista Core 12",
    ["RetailerCore 13"]: "Minorista Core 13",
    ["Retailer Id is compulsory"]:
      "La identificación del minorista es obligatoria",
    ["Retailer Id is Invalid"]: "El ID del minorista no es válido",
    ["Kindly select the core"]: "Por favor seleccione el núcleo",
    ["Limit Type is compulsory"]: "El tipo de límite es obligatorio",
    ["Kindly select the retailer"]: "Por favor seleccione el minorista",
    ["Kindly select the Diamond Dealer"]:
      "Por favor seleccione el distribuidor de diamantes",
    ["Kindly select the Vendor"]: "Por favor seleccione el proveedor",
    ["No Dealer Selected"]: "No se ha seleccionado ningún distribuidor",
    ["Please Select Dealer."]: "Por favor seleccione distribuidor.",
    ["Select Dealer ID"]: "Seleccionar ID del distribuidor",
    ["Select File Type"]: "Seleccionar tipo de archivo",
    ["Kindly select Dealer ID"]: "Por favor seleccione el ID del distribuidor",
    ["FTP Username is compulsory"]: "El nombre de usuario FTP es obligatorio",
    ["FTP Password is compulsory"]: "La contraseña FTP es obligatoria",
    ["Kindly select File Type"]: "Por favor seleccione el tipo de archivo",
    ["Path is Compulsory"]: "El camino es obligatorio",
    ["Dealer ID is compulsory"]:
      "La identificación del distribuidor es obligatoria",
    ["Api Url is compulsory"]: "La URL de la API es obligatoria",
    ["Api Key is compulsory"]: "La clave API es obligatoria",
    ["Are you sure you want to discard ?"]:
      "¿Estás seguro que deseas descartar?",
    ["Are you sure you want to discard all ?"]:
      "¿Estás seguro que deseas descartar todo?",
    ["Enter valid DealerID"]: "Ingrese un DealerID válido",
    ["All Designer"]: "Todo diseñador",
    ["Please select designer."]: "Por favor seleccione diseñador.",
    ["Please select image."]: "Por favor seleccione imagen.",
    ["View Inquiry"]: "Ver consulta",
    ["Export"]: "Exportar",
    ["Jewelry Retailer Click Report"]:
      "Informe de clics de minoristas de joyería",
    ["Jewelry Vendor Click Report"]: "Informe de clics de vendedor de joyas",
    ["Global Diamond"]: "Diamante global",
    ["Extended Diamond Chart Report"]:
      "Informe de gráfico de diamantes ampliado",
    ["Global Jewelry"]: "Joyas globales",
    ["Jewelry Category Reports"]: "Informes de categorías de joyería",
    ["Jewelry Metal Type Reports"]:
      "Informes sobre tipos de metales en joyería",
    ["Page number is compulsory"]: "El número de página es obligatorio",
    ["First Tier Page Name is compulsory"]:
      "El nombre de la página del primer nivel es obligatorio",
    ["Second Tier Page Name is compulsory"]:
      "El nombre de la página de segundo nivel es obligatorio",
    ["Third Tier Page Name is compulsory"]:
      "El nombre de la página del tercer nivel es obligatorio",
    [".JPG | .JPEG | .PNG Format"]: "Formato .JPG | .JPEG | .PNG",
    ["Select Start Time"]: "Seleccionar hora de inicio",
    ["Select End Time"]: "Seleccionar hora de finalización",
    ["Please select the image"]: "Por favor seleccione la imagen",
    ["ShowName is compulsory"]: "ShowName es obligatorio",
    ["Website is compulsory"]: "El sitio web es obligatorio",
    ["Kindly select the Show Start Time"]:
      "Por favor seleccione la hora de inicio del espectáculo",
    ["Kindly select the Show End Time"]:
      "Por favor seleccione la hora de finalización del programa",
    ["Kindly select the Show Start Date"]:
      "Por favor seleccione la fecha de inicio del espectáculo",
    ["Kindly select the Show End Date"]:
      "Por favor seleccione la fecha de finalización del programa",
    ["Color Name is compulsory"]: "El nombre del color es obligatorio",
    ["Select"]: "Seleccionar",
    ["Please select compose."]: "Por favor seleccione redactar.",
    ["Please enter name."]: "Por favor, introduzca el nombre.",
    ["Please enter email."]: "Por favor, introduzca su correo electrónico.",
    ["Email is not valid."]: "El correo electrónico no es válido.",
    ["Please enter subject."]: "Por favor, introduzca el asunto.",
    ["Please enter summary."]: "Por favor, introduzca el resumen.",
    ["Option 1"]: "Opción 1",
    ["Option 2"]: "Opción 2",
    ["GIA Website"]: "Sitio web de GIA",
    ["Yes"]: "Sí",
    ["Borderline"]: "Límite",
    ["No"]: "No",
    ["Very Slight"]: "Muy leve",
    ["Slight"]: "Leve",
    ["Faint"]: "Débil",
    ["Medium"]: "Medio",
    ["Strong"]: "Fuerte",
    ["Very Strong"]: "Acérrimo",
    ["No Yellow"]: "Sin amarillo",
    ["No Green"]: "Sin verde",
    ["No Grey"]: "Sin gris",
    ["No Black"]: "No negro",
    ["No Pink"]: "Sin rosa",
    ["No Blue"]: "Sin azul",
    ["No Brown"]: "Sin marrón",
    ["Light"]: "Luz",
    ["Heavy"]: "Pesado",
    ["Extremely Thin"]: "Extremadamente delgada",
    ["Very Thin"]: "Muy delgada",
    ["Thin"]: "Delgado",
    ["Slightly Thin"]: "Un poco delgada",
    ["Slightly Thick"]: "Un poco grueso",
    ["Thick"]: "Grueso",
    ["Very Thick"]: "Muy grueso",
    ["Extremely Thick"]: "Extremadamente grueso",
    ["Extra Thin"]: "Extra fino",
    ["Bearding"]: "Barba",
    ["Brown Patch of Color"]: "Mancha de color marrón",
    ["Bruise"]: "Moretón",
    ["Cavity"]: "Cavidad",
    ["Chip"]: "Chip",
    ["Cleavage"]: "Escisión",
    ["Cloud"]: "Nube",
    ["Crystal"]: "Cristal",
    ["Crystal Surface"]: "Superficie de cristal",
    ["Etch Channel"]: "Canal de grabado",
    ["Extra Facet"]: "Faceta extra",
    ["Feather"]: "Pluma",
    ["Flux Remnant"]: "Resto de flujo",
    ["Indented Natural"]: "Sangrado natural",
    ["Internal Graining"]: "Granulado interno",
    ["Internal Inscription"]: "Inscripción interna",
    ["Internal Laser Drilling"]: "Perforación láser interna",
    ["Knot"]: "Nudo",
    ["Laser Drill Hole"]: "Agujero perforado con láser",
    ["Manufacturing Remnant"]: "Remanente de fabricación",
    ["Minor Details of Polish"]: "Pequeños detalles del polaco",
    ["Natural"]: "Natural",
    ["Needly"]: "Necesitado",
    ["No Inclusion"]: "Sin inclusión",
    ["Pinpoint"]: "Determinar con precisión",
    ["Reflecting Surface Graining"]: "Granulado superficial reflectante",
    ["Surface Graining"]: "Granulado superficial",
    ["Twinning Wisp"]: "Hilo gemelo",
    ["Small"]: "Pequeño",
    ["Very Small"]: "Pequeñito",
    ["Large"]: "Grande",
    ["Very Large"]: "Muy grande",
    ["Abraded"]: "Desgastado",
    ["Chipped"]: "Astillado",
    ["Pointed"]: "Puntiagudo",
    ["Laser Drilled"]: "Perforado con láser",
    ["Clarity Enhanced"]: "Claridad mejorada",
    ["Color Enhanced"]: "Color mejorado",
    ["Irradiated"]: "Irradiado",
    ["HPHT"]: "Alta presión arterial",
    ["Search All Stones"]: "Buscar todas las piedras",
    ["Search Single Stone"]: "Buscar Piedra Individual",
    ["Search Match Pairs"]: "Buscar pares de coincidencias",
    ["Emerald"]: "Esmeralda",
    ["SI1"]: "SI1",
    ["VG"]: "VG",
    ["B&H Diamonds"]: "Diamantes B&H",
    ["Chicago, IL"]: "Chicago, Illinois",
    ["Category 1"]: "Categoría 1",
    ["Category 2"]: "Categoría 2",
    ["Category 3"]: "Categoría 3",
    ["Material 1"]: "Material 1",
    ["Material 2"]: "Material 2",
    ["Material 3"]: "Material 3",
    ["Child"]: "Niño",
    ["Mens"]: "De los hombres",
    ["Teen"]: "Adolescente",
    ["Unisex"]: "Unisexo",
    ["Womens"]: "De las mujeres",
    ["Vendor 1"]: "Proveedor 1",
    ["Vendor 2"]: "Proveedor 2",
    ["Abc"]: "Abecedario",
    ["Xyz"]: "Xyz",
    ["Tom"]: "Tomás",
    ["Gemstone 1"]: "Piedra preciosa 1",
    ["Gemstone 2"]: "Piedra preciosa 2",
    ["Gemstone 3"]: "Piedra preciosa 3",
    ["Collection 1"]: "Colección 1",
    ["Collection 2"]: "Colección 2",
    ["Collection 3"]: "Colección 3",
    ["Collection 4"]: "Colección 4",
    ["Sales Rep"]: "Representante de ventas",
    ["Jewelry Access"]: "Acceso a joyería",
    ["RingBuilder Access"]: "Acceso a RingBuilder",
    ["Hide Price"]: "Ocultar precio",
    ["Auto Sync"]: "Sincronización automática",
    ["Data & Permissions"]: "Datos y permisos",
    ["Make Appointment"]: "Hacer cita",
    ["Store Notes"]: "Notas de la tienda",
    ["Disconnect Retailer"]: "Desconectar Minorista",
    ["Conversation data is compulsory"]:
      "Los datos de conversación son obligatorios",
    ["Note Title is compulsory"]: "Nota El título es obligatorio",
    ["Note Description"]: "Descripción de la nota",
    ["Appointment data is compulsory"]: "Los datos de la cita son obligatorios",
    ["Access"]: "Acceso",
    ["Avatar"]: "Avatar",
    ["Job Title"]: "Título profesional",
    ["E-Mail"]: "Correo electrónico",
    ["Phone No."]: "Número de teléfono",
    ["Locations"]: "Ubicaciones",
    ["Store Bio"]: "Biografía de la tienda",
    ["Years In Business"]: "Años en el negocio",
    ["Create A New Note"]: "Crear una nueva nota",
    ["Edit Note"]: "Editar nota",
    ["Monday"]: "Lunes",
    ["Tuesday"]: "Martes",
    ["December"]: "Diciembre",
    ["October"]: "Octubre",
    ["July"]: "Julio",
    ["Bhavin Patel"]: "Bhavin Patel",
    ["Started a"]: "Comenzó una",
    ["with your store."]: "con tu tienda.",
    ["Create Appointment"]: "Crear cita",
    ["Calendars"]: "Calendarios",
    ["Calendar"]: "Calendario",
    ["Day"]: "Día",
    ["Engagement"]: "Compromiso",
    ["Cleaning"]: "Limpieza",
    ["Repair"]: "Reparar",
    ["Wedding"]: "Boda",
    ["Select Date"]: "Seleccione fecha",
    ["Select Calendar"]: "Seleccionar Calendario",
    ["Select Contact"]: "Seleccionar contacto",
    ["ABC"]: "abecedario",
    ["John"]: "John",
    ["XYZ"]: "XYZ",
    ["User"]: "Usuario",
    ["Set Day"]: "Establecer día",
    ["Thursday"]: "Jueves",
    ["Friday"]: "Viernes",
    ["Saturday"]: "Sábado",
    ["Set Time"]: "Establecer hora",
    ["Enter Name."]: "Introduzca el nombre.",
    ["Enter Email Address."]: "Introduzca la dirección de correo electrónico.",
    ["Enter Friend's Name."]: "Introduzca el nombre del amigo.",
    ["Enter Friend's Email Address."]:
      "Introduzca la dirección de correo electrónico de su amigo.",
    ["Search all"]: "Buscar todo",
    ["Inventory is compulsory"]: "El inventario es obligatorio",
    ["Matched Pair Stock is compulsory"]:
      "Las acciones de pares emparejados son obligatorias",
    ["Stones are compulsory"]: "Las piedras son obligatorias",
    ["Size is compulsory"]: "El tamaño es obligatorio",
    ["Cost is compulsory"]: "El coste es obligatorio",
    ["Kindly select the shape"]: "Por favor seleccione la forma",
    ["Kindly select the color"]: "Por favor seleccione el color",
    ["Kindly select the fancy codes"]:
      "Por favor seleccione los códigos de lujo",
    ["Kindly select the fancy names"]:
      "Por favor seleccione los nombres elegantes",
    ["Other is compulsory"]: "El resto es obligatorio",
    ["Kindly select the clarity"]: "Por favor seleccione la claridad",
    ["Kindly select the cut grade"]: "Por favor seleccione el grado de corte",
    ["Kindly select the certificate"]: "Por favor seleccione el certificado",
    ["Kindly select the certificate number"]:
      "Por favor seleccione el número de certificado",
    ["Depth is compulsory"]: "La profundidad es obligatoria",
    ["Table is compulsory"]: "La mesa es obligatoria",
    ["Measurements are compulsory"]: "Las medidas son obligatorias",
    ["Kindly select the polish value"]:
      "Por favor seleccione el valor del esmalte",
    ["Kindly select the symmetry"]: "Por favor seleccione la simetría",
    ["Kindly select the fluorescence"]: "Por favor seleccione la fluorescencia",
    ["Kindly select the girdle"]: "Por favor seleccione la faja",
    ["Kindly select the culet"]: "Por favor seleccione el culet",
    ["Kindly select the culet condition"]:
      "Por favor seleccione la condición de la culata",
    ["Crown Angle is compulsory"]: "El ángulo de la corona es obligatorio",
    ["Pavillion Angle is compulsory"]: "El ángulo del pabellón es obligatorio",
    ["Comments are compulsory"]: "Los comentarios son obligatorios",
    ["Store Location is compulsory"]:
      "La ubicación de la tienda es obligatoria",
    ["Employee Name is compulsory"]: "El nombre del empleado es obligatorio",
    ["Employee ID is compulsory"]:
      "La identificación del empleado es obligatoria",
    ["Employee ID is Invalid"]: "El ID del empleado no es válido",
    ["Please select file."]: "Por favor seleccione archivo.",
    ["Option 3"]: "Opción 3",
    ["Golden"]: "Dorado",
    ["FB"]: "pensión completa",
    ["FBGNY"]: "FBGNY",
    ["Brown"]: "Marrón",
    ["FL"]: "Florida",
    ["VVS1"]: "VVS1",
    ["Excellent 1"]: "Excelente 1",
    ["Strong Blue"]: "Azul fuerte",
    ["Medium Blue"]: "Azul medio",
    ["Extremely"]: "Extremadamente",
    ["Do not include pricing informatin in comments."]:
      "No incluya información de precios en los comentarios.",
    ["Video Url"]: "URL del vídeo",
    ["No file chosen"]: "No se ha elegido ningún archivo",
    ["Please select delimiter."]: "Por favor seleccione delimitador.",
    ["Diamond Import"]: "Importación de diamantes",
    ["RAPNET"]: "RED DE RELACIONES",
    ["IDEX"]: "IDEX",
    ["POLYGON"]: "POLÍGONO",
    ["DIRECT LINK"]: "ENLACE DIRECTO",
    ["Use Rapnet Instant Inventory Service."]:
      "Utilice el servicio de inventario instantáneo de Rapnet.",
    ["Standard Diamond Form"]: "Forma de diamante estándar",
    ["Diamond Importing"]: "Importación de diamantes",
    ["Alphanumeric"]: "Alfanumérico",
    ["ProductName1"]: "Nombre del producto1",
    ["Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate."]:
      "Título corto del producto. Este se mostrará en las páginas de productos. Si se incluyen productos secundarios, incluya el color y el tamaño en el nombre para diferenciarlos.",
    ["Accessories"]: "Accesorios",
    ["Tiaras"]: "Tiaras",
    ["Womans Fashion Bag"]: "Bolso de moda para mujer",
    ["Atia 81"]: "Atia 81",
    ["Fashion Earrings"]: "Pendientes de moda",
    ["Chandelier"]: "Araña",
    ["Charms"]: "Amuletos",
    ["Beads"]: "Rosario",
    ["Bracelets"]: "Esposas",
    ["Product Name1.xls"]: "Nombre del producto1.xls",
    ["Delivery Time"]: "El tiempo de entrega",
    ["hours"]: "horas",
    ["Featured"]: "Presentado",
    ["Product name is compulsory"]: "El nombre del producto es obligatorio",
    ["Retail Description is compulsory"]:
      "La descripción minorista es obligatoria",
    ["Retail Price is compulsory"]:
      "El precio de venta al público es obligatorio",
    ["Kindly select the Material Type"]:
      "Por favor seleccione el tipo de material",
    ["Kindly select the Category"]: "Por favor seleccione la categoría",
    ["Kindly select all the necessary Field from essential"]:
      "Por favor seleccione todos los campos necesarios de esencial",
    ["Fixed"]: "Fijado",
    ["WholeSale"]: "Al por mayor",
    ["Diamond Upload"]: "Subida de diamantes",
    ["Please select image file only."]: "Seleccione sólo el archivo de imagen.",
    ["Please select zip file only."]: "Seleccione sólo el archivo zip.",
    ["Please select .csv|xls|txt|json file only."]:
      "Seleccione únicamente archivos .csv|xls|txt|json.",
    ["Please select currency."]: "Por favor seleccione moneda.",
    ["Please select rounding."]: "Por favor seleccione redondeo.",
    ["Please enter default markup."]: "Introduzca el marcado predeterminado.",
    ["Please enter default markup value less then 100%."]:
      "Introduzca un valor de marcado predeterminado menor al 100%.",
    ["Select Currency"]: "Seleccione moneda",
    ["Select Rounding"]: "Seleccionar redondeo",
    ["Collection name is compulsory"]:
      "El nombre de la colección es obligatorio",
    ["Kindly select the Brand Name"]: "Por favor seleccione la marca",
    ["Need to select atleast one category"]:
      "Es necesario seleccionar al menos una categoría",
    ["Overnight Mountings Ring Builder"]:
      "Generador de anillos para montajes nocturnos",
    ["Data Ratings"]: "Calificaciones de datos",
    ["Victor"]: "Vencedor",
    ["Manage Inventory"]: "Administrar inventario",
    ["Manage Location"]: "Administrar ubicación",
    ["Vendor Type"]: "Tipo de proveedor",
    ["Designer"]: "Diseñador",
    ["Watch Brand"]: "Marca de reloj",
    ["Bridal Designer"]: "Diseñador de novias",
    ["Connection Status"]: "Estado de la conexión",
    ["Vendor Profile"]: "Perfil del vendedor",
    ["Why Choose Overnight?"]: "¿Por qué elegir Overnight?",
    ["Inventory"]: "Inventario",
    ["Page"]: "Página",
    ["of"]: "de",
    ["pages"]: "páginas",
    ["per page"]: "por página",
    ["Product Deactivation Guidelines"]:
      "Pautas para la desactivación de productos",
    ["Activate/Deactivate All"]: "Activar/Desactivar todo",
    ["Products"]: "Productos",
    ["Jack"]: "Jacobo",
    ["Lucy"]: "Lucy",
    ["One"]: "Uno",
    ["Two"]: "Dos",
    ["Male"]: "Masculino",
    ["Female"]: "Femenino",
    ["Save Changes"]: "Guardar cambios",
    ["Trade Show Appointment"]: "Cita para feria comercial",
    ["Preferred"]: "Privilegiado",
    ["Questions"]: "Preguntas",
    ["I Am Interested In Placing Your Inventory On My Website."]:
      "Estoy interesado en colocar su inventario en mi sitio web.",
    ["Connected"]: "Conectado",
    ["Request Update"]: "Solicitar actualización",
    ["Image Preview"]: "Vista previa de la imagen",
    ["By Size"]: "Por tamaño",
    ["By Price"]: "Por precio",
    ["United States - Dollar - $"]: "Estados Unidos - Dólar - $",
    ["Great Britan - Pound - £"]: "Gran Bretaña - Libra - £",
    ["Australia - Dollar - $"]: "Australia - Dólar - $",
    ["size"]: "tamaño",
    ["Custom"]: "Costumbre",
    ["US Dollars"]: "Dólares estadounidenses",
    ["Great Britain Pound"]: "Libra esterlina de Gran Bretaña",
    ["Apex Diamonds"]: "Diamantes Apex",
    ["Asscher"]: "Asesino",
    ["Cushion"]: "Almohadón",
    ["Best Diamonds"]: "Los mejores diamantes",
    ["Signature Diamonds"]: "Diamantes de firma",
    ["Normal Diamonds"]: "Diamantes normales",
    ["Diamond Link URL"]: "URL del enlace de diamante",
    ["Automatically Accept New Diamond Dealers"]:
      "Acepte automáticamente nuevos distribuidores de diamantes",
    ["When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."]:
      'Cuando un nuevo comerciante de diamantes se una a GemFind, se agregará automáticamente a su lista de comerciantes de diamantes conectados. Cambie esta opción a "No" si prefiere que los comerciantes de diamantes no se agreguen automáticamente a su cuenta.',
    ["Sort Search Results Initially By"]:
      "Ordenar los resultados de búsqueda inicialmente por",
    ["Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."]:
      "Cambie este valor para modificar el campo de ordenación inicial de los resultados de búsqueda. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Order Search Results Initially By"]:
      "Ordenar resultados de búsqueda inicialmente por",
    ["Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."]:
      "Cambie este valor para modificar el orden de clasificación inicial del campo de clasificación inicial. Déjelo en blanco para el valor predeterminado (ninguno).",
    ["Show In House Diamonds Column with SKU (for New GF Link Only)"]:
      "Muestra la columna de diamantes internos con el código SKU (solo para el nuevo enlace de GF)",
    ["Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."]:
      "Seleccione si desea mostrar columnas de diamantes de la tienda en los resultados de búsqueda. También se mostrará el SKU de la tienda en la ventana emergente de resultados y en la página de detalles.",
    ["Show In House Diamonds First"]: "Mostrar primero los diamantes internos",
    ["Choose whether to display in house diamonds first in the search result."]:
      "Elija si desea que los diamantes internos se muestren primero en el resultado de la búsqueda.",
    ["Show GIA report check link"]:
      "Mostrar enlace de verificación del informe GIA",
    ["When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."]:
      "Cuando esté habilitado, esta opción mostrará un enlace al certificado de diamante GIA en el sitio web de GIA; cuando esté deshabilitada, esta opción mostrará un enlace a la imagen del certificado GIA.",
    ["Show Advance options as Default in Diamond Search"]:
      "Mostrar opciones avanzadas como predeterminadas en la búsqueda de Diamond",
    ["Show Certificate in Diamond Search"]:
      "Mostrar certificado en Búsqueda de diamantes",
    ["Choose whether to display a link to the diamond certificate or not."]:
      "Elija si desea mostrar un enlace al certificado de diamante o no.",
    ["Show EGL Certificate Variants"]: "Mostrar variantes del certificado EGL",
    ["Choose whether to display EGL Certificate Variants or not."]:
      "Elija si desea mostrar las variantes del certificado EGL o no.",
    ["Show Request Certificate"]: "Mostrar solicitud de certificado",
    ["When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."]:
      "Cuando “Mostrar enlace de verificación de informe de GIA” está configurado en NO, esta opción le permite elegir si desea mostrar o no el enlace “Solicitar certificado”. Este enlace mostrará la imagen del certificado de GIA (si está disponible) en lugar del certificado en el sitio web de GIA, y si “Deshabilitar enlaces de certificados en Diamond Search” está configurado en SÍ, este enlace abrirá un formulario “Solicitar más información”.",
    ["Disable Certificate Links in Diamond Search"]:
      "Deshabilitar los enlaces de certificados en Diamond Search",
    ["Hide certificates and show only request form."]:
      "Ocultar certificados y mostrar solo el formulario de solicitud.",
    ["Show Color Items out of Range"]:
      "Mostrar elementos de color fuera de rango",
    ["Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."]:
      'Elija si el último botón de filtro de color de diamante mostrado también mostrará cualquier color restante de ese tipo o superior, hasta "Z".',
    ["Show Clarity Items out of Range"]:
      "Mostrar elementos de claridad fuera de rango",
    ["Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."]:
      "Elija si el último botón de filtro de Claridad de diamante mostrado también mostrará cualquier claridad restante de ese tipo o superior, hasta “I3”.",
    ["Accept Total Price for Diamond"]: "Aceptar precio total por diamante",
    ["When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."]:
      "Al cargar diamantes en JewelCloud con una hoja de datos, el proceso predeterminado para calcular el precio de costo es multiplicar el costo por quilate por el valor en quilates. Cuando esta opción está configurada en SÍ, los precios en la hoja de datos de diamantes cargada se aceptarán como precio de costo total.",
    ["Choose whether to display the link which allows the customer the ability to Drop a Hint."]:
      "Elija si desea mostrar el enlace que permite al cliente dejar una pista.",
    ["Email A Friend"]: "Enviar un correo electrónico a un amigo",
    ["Choose whether to display the link which allows the customer the ability to Email A Friend."]:
      "Elija si desea mostrar el enlace que permite al cliente enviar un correo electrónico a un amigo.",
    ["Show Origin On Diamond Search"]:
      "Mostrar origen en la búsqueda de diamantes",
    ["Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."]:
      "Seleccione si desea mostrar el menú desplegable “Origen” dentro de los filtros de Búsqueda avanzada. (Por ejemplo, Todos, Canadiense, Creado en laboratorio).",
    ["Show vendor comments on details page"]:
      "Mostrar comentarios del proveedor en la página de detalles",
    ["Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."]:
      "Elija si desea mostrar los comentarios realizados por los proveedores de diamantes en la página de detalles de cada piedra en los resultados de búsqueda de diamantes.",
    ["Show Contact Number"]: "Mostrar número de contacto",
    ["Choose whether to use show contact Number or not."]:
      "Elija si desea utilizar la opción para mostrar número de contacto o no.",
    ["Choose whether to use show addresses or not."]:
      "Elija si desea utilizar mostrar direcciones o no.",
    ["Alternate diamond details link URL"]:
      "URL del enlace con detalles de diamantes alternativos",
    ["Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "Cambie este valor para modificar el hipervínculo actual a los detalles del diamante. Puede cambiarlo para que vincule a su propia página de detalles del diamante o a su propio carrito de compras. Los parámetros de detalles del diamante adicionales se pasan en la URL cuando este valor está presente. Déjelo en blanco para el valor predeterminado.",
    ["Use Custom Detail URL for Diamonds?"]:
      "¿Utilizar URL de detalles personalizada para diamantes?",
    ["Choose whether to enable or disable the “Alternate diamond details link URL” option."]:
      "Elija si desea habilitar o deshabilitar la opción “URL de enlace de detalles de diamante alternativo”.",
    ["Show Diamond Prices"]: "Mostrar precios de diamantes",
    ["You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."]:
      "Si lo desea, puede optar por no mostrar los precios de los diamantes en los resultados de búsqueda. El campo de rango de precios en el formulario de búsqueda no se muestra cuando no se muestran los precios.",
    ["Markup Your Own Inventory"]: "Aumente su propio inventario",
    ["Choose whether to apply markup on diamond inventory uploaded by you."]:
      "Elija si desea aplicar un marcado en el inventario de diamantes cargado por usted.",
    ["Show Price Per Carat"]: "Mostrar precio por quilate",
    ["Choose whether to display the price per carat value on the diamond details page “Specifications” tab."]:
      "Elija si desea mostrar el precio por valor de quilate en la pestaña “Especificaciones” de la página de detalles del diamante.",
    ["Show Instagram Share"]: "Mostrar Instagram Compartir",
    ["Choose whether to display Instagram Share or not."]:
      "Elija si desea mostrar Instagram Share o no.",
    ["Call to Action Button"]: "Botón de llamada a la acción",
    ["AddToCart Button"]: "Botón Agregar al carrito",
    ["Schedule a Viewing"]: "Programar una visita",
    ["Alternate Shopping Cart URL"]: "URL de carrito de compras alternativo",
    ["Show AddToCart Button On Search Page"]:
      "Mostrar el botón Agregar al carrito en la página de búsqueda",
    ["Choose whether to display the “Add to Cart” button on Search Page or not."]:
      "Elija si desea mostrar el botón “Agregar al carrito” en la página de búsqueda o no.",
    ["Request Diamond"]: "Solicitar diamante",
    ["Compare Diamond"]: "Comparar Diamante",
    ["Select Option"]: "Seleccionar opción",
    ["Ring Builder Version I"]: "Constructor de anillos versión I",
    ["Ring Builder Version II"]: "Constructor de anillos versión II",
    ["Ring Builder Version III"]: "Constructor de anillos versión III",
    ["Ring Builder Version IV"]: "Constructor de anillos versión IV",
    ["Ring Builder Version V"]: "Constructor de anillos versión V",
    ["Earrings"]: "Pendientes",
    ["18k Yellow Gold"]: "Oro amarillo de 18 quilates",
    ["18k Rose Gold"]: "Oro rosa de 18 quilates",
    ["AUD"]: "Dólar australiano",
    ["GBP (Pound)"]: "GBP (libra)",
    ["USD"]: "Dólar estadounidense",
    ["Items View"]: "Vista de elementos",
    ["List View"]: "Vista de lista",
    ["We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest"]:
      "Ofrecemos más de 50.000 montajes y semimontajes para elegir. Overnight tiene una de las mayores",
    ["In Stock"]: "En stock",
    ["inventories in the industry. All of our"]:
      "inventarios en la industria. Todos nuestros",
    ["mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as"]:
      "Los engastes y semiengastes se pueden devolver en un plazo de 10 días hábiles para obtener un reembolso completo. Nuestra línea completa se ofrece en plata esterlina, 10k, 14k, 18k, platino 950 y paladio 950. Todos los engastes y semiengastes de oro se pueden pedir en colores blanco, amarillo o rosa. Fundiciones disponibles en un plazo de 3 días hábiles, agotados. Los engastes pulidos están disponibles en un plazo de 3 a 5 días hábiles, y los semiengastes y los productos terminados están disponibles en un plazo de 5 a 7 días hábiles. Cualquiera de nuestros más de 50 000 estilos se puede pedir como",
    ["Authentix"]: "Autentix",
    ["Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented"]:
      "Muestras. Cualquier estilo se puede modificar de cualquier forma para satisfacer las necesidades de sus clientes. El talento de Overnight",
    ["custom crafted department"]: "Departamento de artesanía personalizada",
    ["can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs"]:
      "Podemos crear cualquier cosa como un nuevo modelo utilizando CAD y tallado a mano. Empleamos a algunos de los engastadores más talentosos de la industria que pueden engarzar su(s) piedra(s). Ofrecemos 7 calidades de diamantes diferentes, incluidos diamantes negros y diamantes champán. Nuestro departamento de atención al cliente tiene un gran conocimiento de todos los aspectos de la joyería y lo ayudará de manera amable y profesional. El equipo de desarrollo de productos de Overnight siempre está creando nuevos estilos, desde los básicos hasta los diseños de moda más vanguardistas, para garantizar que encontrará lo que está buscando. Todos nuestros engastes se fabrican con orgullo en Nueva York y pasan por un estricto proceso de control de calidad antes de salir de nuestras instalaciones, lo que garantiza que obtenga un excelente producto de manera oportuna. Overnight se reserva el derecho de rechazar o retirar cualquier información, imagen o contenido del producto que se utilice en los sitios web y catálogos de los clientes.",
    ["Please Select Manufacturer"]: "Por favor seleccione el fabricante",
    ["Please select markup"]: "Por favor seleccione marcado",
    ["Please Enter Min Range"]: "Por favor ingrese el rango mínimo",
    ["Please Enter Max Range"]: "Por favor, introduzca el rango máximo",
    ["Please Enter Markup"]: "Por favor, introduzca el marcado",
    ["Malaysia - Riggit - $"]: "Malasia - Riggit - $",
    ["New Zealand - Dollar - $"]: "Nueva Zelanda - Dólar - $",
    ["Canadian - Dollar - $"]: "Canadiense - Dólar - $",
    ["Singapore - Dollar - S$"]: "Singapur - Dólar - S$",
    ["Chinese - Yuan - ¥"]: "Chino - Yuan - ¥",
    ["Korean - Won - ₩"]: "Coreano - Won - ₩",
    ["Denmark - Danish Krone - kr."]: "Dinamarca - Corona danesa - kr.",
    ["South African - Rand - R"]: "Sudafricano - Rand - R",
    ["Europe - Euro - €"]: "Europa - Euro - €",
    ["Swiss - Franc - CHf"]: "Franco suizo - CHF",
    ["Swedish Krona - Sweden - SEK"]: "Corona sueca - Suecia - SEK",
    ["Diamond Link Shopping Cart Configuration Instructions"]:
      "Instrucciones de configuración del carrito de compras de Diamond Link",
    ["We will be sending parameters to the page on your website as below url"]:
      "Enviaremos parámetros a la página de su sitio web como se muestra a continuación.",
    ["Example Url"]: "Ejemplo de URL",
    ["To Get Diamond Detail"]: "Para obtener detalles de diamantes",
    ["Click Here to access API to get diamond detail and checkout how it works online."]:
      "Haga clic aquí para acceder a la API para obtener detalles de los diamantes y comprobar cómo funciona en línea.",
    ["to access API to get diamond detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles del diamante y comprobar cómo funciona en línea.",
    ["Click Here"]: "Haga clic aquí",
    ["Below is the list of parameters with respective possible values which can be supplied to each parameter."]:
      "A continuación se muestra la lista de parámetros con los respectivos valores posibles que se pueden suministrar a cada parámetro.",
    ["DiamondId"]: "Identificación de diamante",
    ["Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"]:
      "Pase el número de diamante. (Lo obtendrá como DiamondID en la cadena de consulta de la URL como se muestra arriba)",
    ["Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."]:
      "Introduzca el número de GemFind que está asignado a su cuenta. Por ejemplo, 720 para GemFind.",
    ["You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."]:
      "Recibirá una respuesta en formato XML de la respuesta de la API. Deberá analizar y utilizar esos datos para crear un diamante como producto en su sitio web.",
    ["DealerLink"]: "Enlace del distribuidor",
    ["Ring Builder Shopping Cart Configuration Instructions"]:
      "Instrucciones de configuración del carrito de compras de Ring Builder",
    ["To Get Setting Detail"]: "Para obtener detalles de la configuración",
    ["to access API to get setting detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles de configuración y comprobar cómo funciona en línea.",
    ["SettingId"]: "Id. de configuración",
    ["Pass setting number of setting. (SettingID from the query string parameter as shown on example url)"]:
      "Pase el número de configuración de la configuración. (SettingID del parámetro de cadena de consulta como se muestra en la URL de ejemplo)",
    ["Masterlink Shopping cart integration instructions"]:
      "Instrucciones de integración del carrito de compras Masterlink",
    ["To Get Product Detail"]: "Para obtener detalles del producto",
    ["You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."]:
      "Puede integrar su propio carrito de compras con los productos de los diseñadores de Gemfind a través de la API. Puede utilizar esta API para que el botón Agregar al carrito de Gemfind agregue el producto al carrito de compras de su sitio web.",
    ["JewelryID"]: "Identificación de joyería",
    ["Pass JewelryID # for which you want to get data."]:
      "Pase el JewelryID # para el cual desea obtener datos.",
    ["Stud Builder Shopping cart integration instructions"]:
      "Instrucciones de integración del carrito de compras de Stud Builder",
    ["There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones"]:
      "Se pasarán dos identificaciones de diamantes separadas por comas, por lo que deberá obtener los detalles del diamante dos veces, una para cada diamante, ya que el perno tiene un par de piedras.",
    ["To Get Stud Mounting Detail"]:
      "Para obtener detalles de montaje de pernos",
    ["to access API to get Stud Mounting detail and checkout how it works online."]:
      "para acceder a la API para obtener detalles de montaje de pernos y verificar cómo funciona en línea.",
    ["Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)"]:
      "Introduzca el JewelryID # del cual desea obtener los datos. (DesignerProductID del parámetro de cadena de consulta como se muestra en la URL de ejemplo)",
    ["Jewelry Category"]: "Categoría de joyería",
    ["Designer Category"]: "Categoría de diseñador",
    ["Bridal Category"]: "Categoría nupcial",
    ["SKU Prefix"]: "Prefijo SKU",
    ["Category Mapping"]: "Mapeo de categorías",
    ["Column Mapping"]: "Mapeo de columnas",
    ["GemFind Category"]: "Categoría GemFind",
    ["Website Category"]: "Categoría del sitio web",
    ["Dealer Stock Number"]: "Número de stock del distribuidor",
    ["Product Description"]: "Descripción del Producto",
    ["Designers Mapping"]: "Mapeo de diseñadores",
    ["GemFind Designers"]: "Diseñadores de GemFind",
    ["Bangles"]: "Brazaletes",
    ["Big & important Bracelets"]: "Pulseras grandes e importantes",
    ["Chain Bracelets"]: "Pulseras de cadena",
    ["Necklaces"]: "Collares",
    ["Chains"]: "Cadenas",
    ["Sync Products To Your Website"]: "Sincronizar productos con su sitio web",
    ["Completed"]: "Terminado",
    ["Sync Products"]: "Sincronizar productos",
    ["Force Full Sync"]: "Forzar sincronización completa",
    ["Detail"]: "Detalle",
    ["Total Products Processed"]: "Total de productos procesados",
    ["Out Going Data Map"]: "Mapa de datos salientes",
    ["API Url"]: "URL de API",
    ["Enable Sync"]: "Habilitar sincronización",
    ["Attrubute Mapping"]: "Asignación de atributos",
    ["Create XML"]: "Crear XML",
    ["Notifications"]: "Notificaciones",
    ["registered with the website"]: "registrado en el sitio web",
    ["Jewelry vendors"]: "Vendedores de joyas",
    ["Active Jewelry"]: "Joyas activas",
    ["Diamond Vendors"]: "Vendedores de diamantes",
    ["Active Diamonds"]: "Diamantes activos",
    ["Contact Us"]: "Contáctenos",
    ["Favorites"]: "Favoritos",
    ["New & Recently Updated Vendors"]:
      "Proveedores nuevos y actualizados recientemente",
    ["View More"]: "Ver más",
    ["Quick Links"]: "Enlaces rápidos",
    ["View My Retailers"]: "Ver mis minoristas",
    ["Update Inventory"]: "Actualizar inventario",
    ["Invite A Retailer"]: "Invitar a un minorista",
    ["Manage Shows"]: "Administrar espectáculos",
    ["Ratings"]: "Calificaciones",
    ["The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated."]:
      "La clasificación de estrellas se basa en siete áreas clave: imágenes, nombre del producto, descripción del producto, categorías/colecciones, atributos, precios y última actualización.",
    ["For details on how you can improve your score, please click here to view this document."]:
      "Para obtener detalles sobre cómo puede mejorar su puntuación, haga clic aquí para ver este documento.",
    ["Import Your Data"]: "Importa tus datos",
    ["Upload Your Jewelry & Diamonds"]: "Sube tus joyas y diamantes",
    ["Import Diamonds"]: "Importar diamantes",
    ["Upload Diamond"]: "Subir diamante",
    ["Import Jewelry"]: "Importar joyas",
    ["Upload Jewelry"]: "Subir joyas",
    ["Video/Images"]: "Vídeo/imágenes",
    ["Email"]: "Correo electrónico",
    ["Friend's Name"]: "Nombre del amigo",
    ["Friend's Email"]: "Correo electrónico del amigo",
    ["Buyer Name is compulsory"]: "El nombre del comprador es obligatorio",
    ["Book Appointment"]: "Reservar cita",
    ["Appointment Description"]: "Descripción de la cita",
    ["Gemfind"]: "Descubrimiento de gemas",
    ["Your recipient will recieve the details on your selected diamonds"]:
      "Su destinatario recibirá los detalles de los diamantes seleccionados.",
    ["Message"]: "Mensaje",
    ["Please select video file only."]: "Seleccione sólo el archivo de vídeo.",
    ["Is Lab Created"]: "¿Se creó el laboratorio?",
    ["Please Enter Link"]: "Por favor, introduzca el enlace",
    ["Please Enter Text"]: "Por favor, introduzca el texto",
    ["View /Edit Mapping Columns"]: "Ver/Editar columnas de mapeo",
    ["Preparing Your Data"]: "Preparando sus datos",
    ["Resubmit"]: "Volver a enviar",
    ["Re-Activate"]: "Reactivar",
    ["Fancy Color Diamond Link Emails"]:
      "Correos electrónicos con enlaces de diamantes de colores de lujo",
    ["Diamond Link Emails"]: "Correos electrónicos de Diamond Link",
    ["Time pieces"]: "Piezas de relojería",
    ["Click Here for GFLINK ECommerce Link Administration"]:
      "Haga clic aquí para la administración de enlaces de comercio electrónico de GFLINK",
    ["View/Update Your Online Dealer Inventory List"]:
      "Ver/Actualizar su lista de inventario de distribuidores en línea",
    ["Change Inventory Mark-Up Values for Markup BY PRICE"]:
      "Cambiar los valores de margen de inventario para el margen POR PRECIO",
    ["Update Search Page Results Appearance"]:
      "Actualizar la apariencia de los resultados de la página de búsqueda",
    ["Update Search Page Results Preferences"]:
      "Actualizar las preferencias de resultados de la página de búsqueda",
    ["For Advanced Users"]: "Para usuarios avanzados",
    ["Change Your Shipping Charge"]: "Cambiar su cargo de envío",
    ["Your GFLINK Addresses are"]: "Sus direcciones GFLINK son",
    ["ADMIN NOTE"]: "NOTA DEL ADMINISTRADOR",
    ["Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory"]:
      "Sus direcciones GFLink DEBEN contener el nombre de dominio correcto, http://platform.stage.jewelcloud.com. El uso de http://platform.stage.jewelcloud.com o cualquier variación de gemfind.com NO es correcto y ya no funciona. Asegúrese de que su URL de GFWatchLink sea correcta. Utilice las URL que se indican a continuación. Los enlaces que se indican a continuación muestran su inventario",
    ["Marked Up"]: "Marcado",
    ["a % determined by the Watch'S PRICE."]:
      "un % determinado por el PRECIO del Reloj.",
    ["Search Only"]: "Sólo búsqueda",
    ["Watch Configurator"]: "Configurador de relojes",
    ["Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs."]:
      "Procedimiento para implementar Watch Configurator: Para integrar Watch Configurator directamente en una de las páginas web de su sitio web, deberá seguir el procedimiento general que se indica a continuación. Este procedimiento variará según la tecnología que utilice su sitio web, y su desarrollador web deberá realizar los ajustes correspondientes. Esto requerirá un trabajo de desarrollo de sitios web personalizado de su parte. Básicamente, tiene dos opciones cuando se trata de incluir Watch Configurator sin problemas en su sitio web: FRAMEs o IFRAMEs.",
    ["FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look."]:
      "FRAMEs: Si su sitio utiliza FRAMEs en un FRAMESET, entonces el Configurador de Watch puede incluirse fácilmente como uno de los contenidos de FRAME. Este es uno de los métodos más simples de incrustar resultados, sin embargo no es tan atractivo como los otros métodos y puede no ser una opción viable para usted si aún no utiliza FRAMEs. Las barras de desplazamiento pueden eliminarse programáticamente para lograr una apariencia más limpia.",
    ["IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window."]:
      "IFRAMEs / LAYERs: Defina un IFRAME flotante (incluya la codificación LAYER para adaptarse a Netscape) y colóquelo en su página donde desea que aparezca el Configurador de Watch. Esto se verá mejor que un FRAME definido como parte de un CONJUNTO DE MARCOS. Se debe prestar cierta atención al tamaño del IFRAME. La ventana IFRAME no se puede redimensionar mediante programación una vez que se ha definido en una página, por lo tanto, debe crear el IFRAME con una ALTURA y ANCHO que se adapten a todo el contenido del Configurador de Watch. La cantidad de resultados/filas devueltas por el Configurador de Watch es constante y garantizará una altura (y ajuste) constante para su ventana IFRAME.",
    ["Search by GEMFIND INVENTORY ID #"]:
      "Búsqueda por N.º de ID de INVENTARIO de GEMFIND",
    ["Enter GEMFIND ID # and CLICK 'GO'"]:
      'Ingrese el número de identificación de GEMFIND y HAGA CLIC EN "IR"',
    ["Go"]: "Ir",
    ["Search by Dealer's Inventory #"]:
      "Búsqueda por número de inventario del concesionario",
    ["Enter Dealer Inventory # and CLICK 'GO'"]:
      'Ingrese el número de inventario del concesionario y HAGA CLIC EN "IR"',
    ["A & M Diamonds"]: "Diamantes A&M",
    ["A & W Gems"]: "Gemas A&W",
    ["A & F Gems"]: "Gemas A&F",
    ["Pendant Builder Shopping cart integration instructions"]:
      "Instrucciones de integración del carrito de compras del generador de colgantes",
    ["To Get Pendant Mounting Detail"]:
      "Para obtener detalles de montaje del colgante",
    ["Please Enter Password."]: "Por favor, introduzca la contraseña.",
    ["Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"]:
      "La contraseña debe contener un mínimo de 6 caracteres, al menos una letra mayúscula, un número y un carácter especial.",
    ["Please Enter Confirm Password."]:
      "Por favor ingrese confirmar contraseña.",
    ["Password And Confirm Password Do Not Match."]:
      "La contraseña y la confirmación de contraseña no coinciden.",
    ["Server Up-Time"]: "Tiempo de actividad del servidor",
    ["Uptime info not available - please try reloading this page"]:
      "La información de disponibilidad no está disponible: intente volver a cargar esta página",
    ["Select Client Name"]: "Seleccione el nombre del cliente",
    ["Active - No Profile"]: "Activo - Sin perfil",
    ["Select Type"]: "Seleccionar tipo",
    ["Select Retailer Status"]: "Seleccionar estado de minorista",
    ["API Url is compulsory"]: "La URL de la API es obligatoria",
    ["API Key is compulsory"]: "La clave API es obligatoria",
    ["Provide Proper DealerID"]:
      "Proporcione la identificación del distribuidor adecuada",
    ["account ?"]: "cuenta ?",
    ["You have select"]: "Usted ha seleccionado",
    ["Please select .png file only."]: "Seleccione sólo archivos .png.",
    ["Please Select Correct Image Size."]:
      "Seleccione el tamaño de imagen correcto.",
    ["All Dealer"]: "Todos los distribuidores",
    ["Please Enter Only Alphabet."]: "Por favor, introduzca sólo el alfabeto.",
    ["Please Select Only Images."]: "Seleccione sólo imágenes.",
    ["API Access Token"]: "Token de acceso a API",
    ["Set Your Available Diamonds"]: "Establezca sus diamantes disponibles",
    ["This will control default diamond filters when users initially visit your DiamondLink."]:
      "Esto controlará los filtros de diamantes predeterminados cuando los usuarios visiten inicialmente su DiamondLink.",
    ["This will control which diamonds are available in your DiamondLink."]:
      "Esto controlará qué diamantes están disponibles en su DiamondLink.",
    ["Lab Setting"]: "Entorno de laboratorio",
    ["Mined Setting"]: "Configuración minada",
    ["Ct."]: "Connecticut.",
    ["Please select zip file only were in Compress images together & upload in a .zip format."]:
      "Seleccione solo el archivo zip donde se comprime la imagen y cárguela en formato .zip.",
    ["Password does not match"]: "La contraseña no coincide",
    ["Name is compulsory"]: "El nombre es obligatorio",
    ["Name is invalid"]: "El nombre no es válido",
    ["Email is compulsory"]: "El correo electrónico es obligatorio",
    ["Confirm Password is compulsory"]: "Confirmar contraseña es obligatorio",
    ["Logins"]: "Inicios de sesión",
    ["User Role Detail"]: "Detalle del rol del usuario",
    ["New User"]: "Nuevo usuario",
    ["Add New User"]: "Agregar nuevo usuario",
    ["Access Level"]: "Nivel de acceso",
    ["Edit User"]: "Editar usuario",
    ["Delete User"]: "Eliminar usuario",
    ["Last Login"]: "Último inicio de sesión",
    ["User Since"]: "Usuario desde",
    ["Select Account type"]: "Seleccione el tipo de cuenta",
    ["Company 1"]: "Compañía 1",
    ["Company 2"]: "Compañía 2",
    ["Company 3"]: "Compañía 3",
    ["Apply"]: "Aplicar",
    ["Language Selection for Platform and Tools"]:
      "Selección de idioma para la plataforma y las herramientas",
    ["Default Language Is"]: "El idioma predeterminado es",
    ["Select Language"]: "Seleccione idioma",
    ["Currency Selection for Platform and Tools"]:
      "Selección de moneda para la plataforma y las herramientas",
    ["Default Currency Is"]: "La moneda predeterminada es",
    ["Please select Image File Only"]: "Seleccione solo archivo de imagen",
    ["Email Address is compulsory"]:
      "La dirección de correo electrónico es obligatoria",
    ["Email Address is Invalid"]:
      "La dirección de correo electrónico no es válida",
    ["Image Name"]: "Nombre de la imagen",
    ["Company Detail"]: "Detalle de la empresa",
    ["Web Site"]: "Sitio web",
    ["Company Bio"]: "Biografía de la empresa",
    ["Update Profile"]: "Actualizar perfil",
    ["Advertising Samples"]: "Muestras de publicidad",
    ["Social Integrations Settings"]:
      "Configuraciones de integraciones sociales",
    ["Website's Facebook App ID Setup"]:
      "Configuración del ID de la aplicación de Facebook del sitio web",
    ["Masterlink Facebook App Setup"]:
      "Configuración de la aplicación Masterlink para Facebook",
    ["MasterLink Facebook canvas App ID"]:
      "ID de la aplicación de Facebook Canvas de MasterLink",
    ["MasterLink Facebook canvas App URL"]:
      "URL de la aplicación de Facebook Canvas de MasterLink",
    ["RingBuilder Facebook App Setup"]:
      "Configuración de la aplicación RingBuilder para Facebook",
    ["RingBuilder Facebook canvas App ID"]:
      "Identificación de la aplicación de Facebook Canvas de RingBuilder",
    ["RingBuilder Facebook canvas App URL"]:
      "URL de la aplicación Facebook Canvas de RingBuilder",
    ["StudBuilder Facebook App Setup"]:
      "Configuración de la aplicación de Facebook de StudBuilder",
    ["StudBuilder Facebook canvas App ID"]:
      "ID de la aplicación de Facebook Canvas de StudBuilder",
    ["StudBuilder Facebook canvas App URL"]:
      "URL de la aplicación Facebook Canvas de StudBuilder",
    ["PendantBuilder Facebook App Setup"]:
      "Configuración de la aplicación de Facebook de PendantBuilder",
    ["PendantBuilder Facebook canvas App ID"]:
      "ID de la aplicación de Facebook Canvas de PendantBuilder",
    ["PendantBuilder Facebook canvas App URL"]:
      "URL de la aplicación de lienzo de Facebook de PendantBuilder",
    ["Diamondlink Facebook App Setup"]:
      "Configuración de la aplicación Diamondlink para Facebook",
    ["DiamondLink Facebook canvas App ID"]:
      "Identificación de la aplicación DiamondLink Facebook Canvas",
    ["DiamondLink Facebook canvas App URL"]:
      "URL de la aplicación DiamondLink Facebook Canvas",
    ["Basic Information"]: "Información básica",
    ["Notiﬁcation Settings"]: "Configuración de notificaciones",
    ["Choose what you want to be notiﬁed about and where."]:
      "Elige qué quieres que te notifiquemos y dónde.",
    ["Inquiries"]: "Consultas",
    ["New Favorites List"]: "Nueva lista de favoritos",
    ["New Favorites"]: "Nuevos favoritos",
    ["Vendor Requests"]: "Solicitudes de proveedores",
    ["Send Upload Report"]: "Enviar informe de carga",
    ["Purchase Orders"]: "Órdenes de compra",
    ["Sales Orders"]: "Órdenes de venta",
    ["Ignore blank uploads"]: "Ignorar cargas en blanco",
    ["SMTP Settings"]: "Configuración SMTP",
    ["Send e-mails from your own address."]:
      "Envía correos electrónicos desde tu propia dirección.",
    ["Mail Server"]: "Servidor de correo",
    ["Port Number"]: "Número de puerto",
    ["Server Requires Authentication"]: "El servidor requiere autenticación",
    ["Use SSL"]: "Utilice SSL",
    ["Test Your SMTP Settings"]: "Pruebe su configuración SMTP",
    ["TEST"]: "PRUEBA",
    ["Add Map Here"]: "Añade el mapa aquí",
    ["Location 1"]: "Ubicación 1",
    ["Address"]: "DIRECCIÓN",
    ["Zip"]: "Cremallera",
    ["Location E-Mail"]: "Ubicación Correo electrónico",
    ["Location Phone"]: "Ubicación Teléfono",
    ["Update Location"]: "Actualizar ubicación",
    ["Hours of Operation"]: "Horario de atención",
    ["Sun"]: "Sol",
    ["Mon"]: "Lun",
    ["Tues"]: "martes",
    ["Wed"]: "Casarse",
    ["Thu"]: "Jue",
    ["Fri"]: "Vie",
    ["Sat"]: "Se sentó",
    ["Location QR Code"]: "Código QR de ubicación",
    ["QR Code Generator"]: "Generador de códigos QR",
    ["View Inventory"]: "Ver inventario",
    ["Please Enter Min Price."]: "Por favor, introduzca el precio mínimo.",
    ["Please Enter Max Price."]: "Por favor, introduzca el precio máximo.",
    ["Please Enter Markup."]: "Por favor, introduzca el marcado.",
    ["Confirmation"]: "Confirmación",
    ["Scheduled Reminder"]: "Recordatorio programado",
    ["Tier Name is Compulsory"]: "El nombre del nivel es obligatorio",
    ["Tier Value is Compulsory"]: "El valor del nivel es obligatorio",
    ["First Tier Value is Compulsory"]:
      "El valor del primer nivel es obligatorio",
    ["Selecting Any Field is compulsory"]:
      "Seleccionar cualquier campo es obligatorio",
    ["Tier Type"]: "Tipo de nivel",
    ["Tier One"]: "Nivel uno",
    ["Tier Two"]: "Nivel dos",
    ["Enter Tier Name"]: "Introduzca el nombre del nivel",
    ["View Location"]: "Ver ubicación",
    ["Country is compulsory"]: "El país es obligatorio",
    ["Country is invalid"]: "El país no es válido",
    ["Contact name is invalid"]: "El nombre de contacto no es válido",
    ["Add to Cart Report"]: "Añadir al carrito Informar",
    ["Wish List Report"]: "Informe de lista de deseos",
    ["Please Enter Message."]: "Por favor ingrese un mensaje.",
    ["View My"]: "Ver mi",
    ["Attribute is compulsory"]: "El atributo es obligatorio",
    ["Please Select csv file only"]: "Seleccione solo archivo csv",
    ["Reset Mapping"]: "Restablecer mapeo",
    ["Vary Pricing By Finger Size"]: "Varía el precio según el tamaño del dedo",
    ["Schedule Reminder"]: "Recordatorio de programación",
    ["Select Finger Size"]: "Seleccione el tamaño del dedo",
    ["Video Type"]: "Tipo de video",
    ["This option will override the data."]: "Esta opción anulará los datos.",
    ["Load Image From URL"]: "Cargar imagen desde URL",
    ["Trade show is compulsory"]: "La feria comercial es obligatoria",
    ["Sales rep is compulsory"]: "El representante de ventas es obligatorio",
    ["Appointment date is compulsory"]: "La fecha de cita es obligatoria",
    ["Buyer name is compulsory"]: "El nombre del comprador es obligatorio",
    ["Question is compulsory"]: "La pregunta es obligatoria",
    ["Time is compulsory"]: "El tiempo es obligatorio",
    ["Write the first message ...."]: "Escribe el primer mensaje....",
    ["Post"]: "Correo",
    ["Select Sales Rep"]: "Seleccionar representante de ventas",
    ["Location Details"]: "Detalles de la ubicación",
    ["Location Email"]: "Ubicación Correo electrónico",
    ["Sunday"]: "Domingo",
    ["AM to"]: "De AM a",
    ["Wednesday"]: "Miércoles",
    ["No record found"]: "No se encontró ningún registro",
    ["Select 1 Tier PageName"]: "Seleccione 1 nombre de página de nivel",
    ["Select 2 Tier PageName"]:
      "Seleccione el nombre de la página de 2 niveles",
    ["Select Location"]: "Seleccione la ubicación",
    ["Use"]: "Usar",
    ["Select File"]: "Seleccionar archivo",
    ["Upload Images"]: "Subir imágenes",
    ["Use this Company Name,  and bio as my Brand name,  and Bio on my authorized Retailer websites."]:
      "Utilice este nombre de empresa y biografía como mi marca y biografía en los sitios web de mis minoristas autorizados.",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
      "Haga clic aquí para configurar sus diferentes mensajes de marketing de marca para utilizarlos en los sitios web de sus minoristas autorizados.",
    ["Since"]: "Desde",
    ["Primary E-mail"]: "Correo electrónico principal",
    ["Primary Phone"]: "Teléfono principal",
    ["Primary Contact Name"]: "Nombre del contacto principal",
    ["Company Logo"]: "Logotipo de la empresa",
    ["Phone no is invalid"]: "El número de teléfono no es válido",
    ["Change"]: "Cambiar",
    ["Product Details"]: "Detalles del producto",
    ["Jewelery Characteristics"]: "Características de las joyas",
    ["SKU #"]: "Código SKU",
    ["Style"]: "Estilo",
    ["Cost ($)"]: "Costo ($)",
    ["MSRP ($)"]: "Precio de venta sugerido ($)",
    ["Width (mm)"]: "Ancho (mm)",
    ["Thickness (mm)"]: "Espesor (mm)",
    ["Length (in)"]: "Longitud (pulgadas)",
    ["Weight (gm)"]: "Peso (g)",
    ["Last Updated On"]: "Última actualización el",
    ["Description"]: "Descripción",
    ["Dealer Info"]: "Información del distribuidor",
    ["Dealer Information"]: "Información del distribuidor",
    ["Company"]: "Compañía",
    ["Contact Person"]: "Persona de contacto",
    ["Fax"]: "Fax",
    ["E-mail"]: "Correo electrónico",
    ["Export All"]: "Exportar todo",
    ["Please select .csv|xls|txt file only."]:
      "Seleccione únicamente archivos .csv|xls|txt.",
    ["Lab Grown Diamond"]: "Diamante cultivado en laboratorio",
    ["Warning: Changing or modifying your"]:
      "Advertencia: Cambiar o modificar su",
    ["will cause the"]: "causará que",
    ["to be reset: Click Yes to proceed"]:
      "Para restablecer: haga clic en Sí para continuar",
    ["In Frame"]: "En el marco",
    ["Save Search"]: "Guardar búsqueda",
    ["Search Saved Successfully."]: "Búsqueda guardada exitosamente.",
    ["Search Reset Successfully."]: "Búsqueda restablecida exitosamente.",
    ["DIAMOND PREVIEW"]: "VISTA PREVIA DEL DIAMANTE",
    ["No Results Found"]: "No se encontraron resultados",
    ["Use The Form Below"]: "Utilice el formulario a continuación",
    ["Search Diamond Stock"]: "Buscar acciones de diamantes",
    ["Comments is Compulsory"]: "Los comentarios son obligatorios",
    ["You Have Successully Requested For Diamond"]:
      "Ha solicitado un diamante con éxito",
    ["Cannot Request For Diamond."]: "No se puede solicitar un diamante.",
    ["Price Range"]: "Gama de precios",
    ["All Certificates"]: "Todos los certificados",
    ["Depth percentage is the height of the diamond measured from the culet to the table, divided by the width of the diamond. The lower the depth %, the larger the diamond will appear (given the same weight), but if this number is too low then the brilliance of the diamond will be sacrificed. The depth percentage is one of the elements that determines the Cut grading."]:
      "El porcentaje de profundidad es la altura del diamante medida desde la culata hasta la mesa, dividida por el ancho del diamante. Cuanto menor sea el porcentaje de profundidad, más grande parecerá el diamante (dado el mismo peso), pero si este número es demasiado bajo, se sacrificará el brillo del diamante. El porcentaje de profundidad es uno de los elementos que determina la clasificación de talla.",
    ["Table percentage is the width of a diamond’s largest facet the table divided by its overall width. It tells you how big the “face” of a diamond is."]:
      "El porcentaje de la tabla es el ancho de la faceta más grande de un diamante dividido por su ancho total. Indica el tamaño de la “cara” de un diamante.",
    ["Symmetry describes how symmetrical the diamond is cut all the way around, which is a contributing factor to a diamond’s sparkle and brilliance. Aim for an Excellent or Very Good symmetry rating for round brilliant shapes, and Excellent to Good for fancy shapes."]:
      "La simetría describe el grado de simetría del corte del diamante en todos sus ángulos, lo que contribuye a su brillo y resplandor. Apunte a una calificación de simetría Excelente o Muy Buena para formas brillantes redondas, y Excelente a Buena para formas elegantes.",
    ["Fluorescence tells you how a diamond responds to ultraviolet light - does it glow under a black light? Diamonds with no fluorescence are generally priced higher on the market, but it is rare for fluorescence to have any visual impact on the diamond; some fluorescence can even enhance the look of the stone. Our site recommends searching for diamonds with none to medium fluorescence, and keeping open the option of strong fluorescence for additional value."]:
      "La fluorescencia te indica cómo responde un diamante a la luz ultravioleta: ¿brilla bajo una luz negra? Los diamantes sin fluorescencia suelen tener un precio más alto en el mercado, pero es poco frecuente que la fluorescencia tenga algún impacto visual en el diamante; un poco de fluorescencia puede incluso mejorar el aspecto de la piedra. Nuestro sitio recomienda buscar diamantes con fluorescencia nula o media y dejar abierta la opción de fluorescencia intensa para obtener un valor adicional.",
    ["Polish describes how smooth the surface of a diamond is. Aim for an Excellent or Very Good polish rating."]:
      "El pulido describe qué tan suave es la superficie de un diamante. El objetivo es obtener una calificación de pulido Excelente o Muy bueno.",
    ["This refers to different types of Price to filter and select the appropriate ring as per your requirements. Look for the best suit price of your chosen ring."]:
      "Esto se refiere a diferentes tipos de precios para filtrar y seleccionar el anillo adecuado según sus requisitos. Busque el precio más adecuado para el anillo que haya elegido.",
    ["Carat is a unit of measurement to determine a diamond’s weight. Typically, a higher carat weight means a larger looking diamond, but that is not always the case. Look for the mm measurements of the diamond to determine its visible size."]:
      "El quilate es una unidad de medida que se utiliza para determinar el peso de un diamante. Normalmente, un mayor peso en quilates significa que el diamante parece más grande, pero no siempre es así. Busque las medidas en mm del diamante para determinar su tamaño visible.",
    ["A diamond’s clarity refers to the tiny traces of natural elements that are trapped inside the stone. 99% of diamonds contain inclusions or flaws. You do not need a flawless diamond - they are very rare and expensive - but you want to look for one that is perfect to the naked eye. Depending on the shape of the diamond, the sweet spot for clarity is usually between VVS2 to SI1."]:
      "La claridad de un diamante se refiere a los minúsculos rastros de elementos naturales que se encuentran atrapados dentro de la piedra. El 99 % de los diamantes contienen inclusiones o defectos. No es necesario que el diamante sea impecable (son muy raros y caros), pero sí es necesario que sea perfecto a simple vista. Según la forma del diamante, el punto óptimo de claridad suele estar entre VVS2 y SI1.",
    ["The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone."]:
      "La escala de colores mide el grado de incoloridad de un diamante. D es el grado más alto y más incoloro, pero también el más caro. Para obtener el máximo valor por su presupuesto, busque una piedra incolora.",
    ["Not to be confused with shape, a diamond’ s cut rating tells you how well its proportions interact with light.By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is.Cut grading is usually not available for fancy shapes(any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in ."]:
      "No debe confundirse con la forma, la calificación de corte de un diamante le indica qué tan bien interactúan sus proporciones con la luz. Al evaluar los ángulos y las proporciones del diamante, la calificación de corte está diseñada para indicarle qué tan brillante es su piedra. La calificación de corte generalmente no está disponible para formas elegantes (cualquier forma que no sea redonda), porque la fórmula matemática que determina el retorno de la luz se vuelve menos confiable cuando se tienen en cuenta diferentes relaciones de largo a ancho.",
    ["A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."]:
      "La forma de un diamante no es lo mismo que su corte. La forma se refiere al contorno general de la piedra y no a sus propiedades refractarias de la luz. Busque una forma que se adapte mejor a la configuración del anillo que ha elegido, así como a las preferencias y la personalidad del destinatario.",
    ["Diamond 1"]: "Diamante 1",
    ["Diamond 2"]: "Diamante 2",
    ["Diamond 1 Grading Report"]: "Informe de calificación del diamante 1",
    ["Diamond 2 Grading Report"]: "Informe de calificación de Diamond 2",
    ["Request Certificate"]: "Solicitar certificado",
    ["Your Diamond Pair"]: "Tu par de diamantes",
    ["For Internal Use Only"]: "Solo para uso interno",
    ["GF Password"]: "Contraseña GF",
    ["Vendor Company"]: "Empresa vendedora",
    ["Vendor City/State"]: "Ciudad/Estado del proveedor",
    ["Vendor Phone Number"]: "Número de teléfono del proveedor",
    ["Vendor E-Mail:"]: "Correo electrónico del proveedor:",
    ["Vendor lot number of the item"]:
      "Número de lote del proveedor del artículo",
    ["Diamond ID"]: "Identificación de diamante",
    ["Have a question regarding this item? Our specialists are available to assist you."]:
      "¿Tiene alguna pregunta sobre este artículo? Nuestros especialistas están disponibles para ayudarle.",
    ["Add To Your Stud"]: "Añade a tu semental",
    ["Ct Weight"]: "Peso del ct",
    ["Your Diamond Pair has a Total Carat Weight of"]:
      "Su par de diamantes tiene un peso total en quilates de",
    ["Carat. These carefully selected diamonds work well together because of their near-identical cut, color,clarity and size. To compare the specific diamond details, see the charts and information below."]:
      "Quilates. Estos diamantes cuidadosamente seleccionados combinan bien entre sí debido a que su talla, color, claridad y tamaño son casi idénticos. Para comparar los detalles específicos de cada diamante, consulte los gráficos y la información a continuación.",
    ["This diamond is graded by"]: "Este diamante está clasificado por",
    [". This provides you an authoritative analysis of your diamond. It also verifies that your diamond meets all the specific quality requirements."]:
      "Esto le proporciona un análisis fiable de su diamante y también verifica que su diamante cumple con todos los requisitos de calidad específicos.",
    ["Email is Invalid"]: "El correo electrónico no es válido",
    ["Reason is Compulsory"]: "La razón es obligatoria",
    ["Location is Compulsory"]: "La ubicación es obligatoria",
    ["Message is Compulsory"]: "El mensaje es obligatorio",
    ["Phone is Compulsory"]: "El teléfono es obligatorio",
    ["Your request has been sent to GemFind"]:
      "Su solicitud ha sido enviada a GemFind",
    ["Your request has not been sent to GemFind."]:
      "Su solicitud no ha sido enviada a GemFind.",
    ["Could Not Add Record"]: "No se pudo agregar el registro",
    ["Sorry we had an error"]: "Lo sentimos, tuvimos un error.",
    ["Your request has been sent to GemFind. A representative will be contacting you shortly."]:
      "Su solicitud ha sido enviada a GemFind. Un representante se pondrá en contacto con usted en breve.",
    ["Record Added Successfully"]: "Registro añadido con éxito",
    ["An email has been sent to your friend,Thanks for sharing!"]:
      "Se ha enviado un correo electrónico a tu amigo, ¡Gracias por compartir!",
    ["An email has not been  sent to your friend,Thanks for sharing!"]:
      "No se ha enviado un correo electrónico a tu amigo, ¡Gracias por compartir!",
    ["Please enter valid password"]:
      "Por favor, introduzca una contraseña válida",
    ["Price Per Carat"]: "Precio por quilate",
    ["Depth %"]: "Profundidad %",
    ["Table %"]: "Mesa %",
    ["Origin"]: "Origen",
    ["SIMILAR DIAMOND PAIRS"]: "PARES DE DIAMANTES SIMILARES",
    ["Certificate"]: "Certificado",
    ["Your Diamond Pair has a Total Carat Weight of 0.52 Carat. These carefully selected diamonds work well together because of their near-identical cut, color, clarity and size. To compare the specific diamond details, see the charts and information below."]:
      "Su par de diamantes tiene un peso total en quilates de 0,52 quilates. Estos diamantes cuidadosamente seleccionados combinan bien entre sí debido a su corte, color, claridad y tamaño casi idénticos. Para comparar los detalles específicos de cada diamante, consulte los gráficos y la información a continuación.",
    ["Grading Report"]: "Informe de calificación",
    ["Are you sure you want to schedule?"]:
      "¿Estás seguro que deseas programar?",
    ["Your Friend's E-Mail"]: "El correo electrónico de tu amigo",
    ["Your Friend's Name"]: "El nombre de tu amigo",
    ["Your E-Mail"]: "Su correo electrónico",
    ["Hint Recipient's Name "]: "Nombre del destinatario de la sugerencia",
    ["Because you deserve this"]: "Porque te mereces esto",
    ["Drop Hint"]: "Sugerencia",
    ["By Phone"]: "Por teléfono",
    ["By Email"]: "Por correo electrónico",
    ["Contact Preference"]: "Preferencias de contacto",
    ["Name is Compulsory"]: "El nombre es obligatorio",
    ["Appointment Date is Compulsory"]: "La fecha de cita es obligatoria",
    ["Appointment Time is Compulsory"]: "La cita previa es obligatoria",
    ["Phone Number is Compulsory"]: "El número de teléfono es obligatorio",
    ["Gift Deadline is Compulsory"]:
      "La fecha límite para la donación es obligatoria",
    ["Personal Message is Compulsory"]: "El mensaje personal es obligatorio",
    ["Recipient's E-Mail is Invalid"]:
      "El correo electrónico del destinatario no es válido",
    ["Recipient's E-Mail is Compulsory"]:
      "El correo electrónico del destinatario es obligatorio",
    ["Recipient's Name is Compulsory"]:
      "El nombre del destinatario es obligatorio",
    ["Email is Compulsory"]: "El correo electrónico es obligatorio",
    ["Your request has been sent to GemFind."]:
      "Su solicitud ha sido enviada a GemFind",
    ["Carat ROUND Cut Diamond"]: "Diamante de talla REDONDA en quilates",
    ["Diamond Pair"]: "Par de diamantes",
    ["Write A Personal Message ..."]: "Escribe un mensaje personal...",
    ["Schedule A Viewing"]: "Programar una visita",
    ["See this item and more in our store"]:
      "Vea este artículo y más en nuestra tienda.",
    ["When are you available?"]: "¿Cuando estás disponible?",
    ["Appointment Time"]: "Hora de la cita",
    ["8 AM"]: "8 a. m.",
    ["8:30 AM"]: "8:30 AM",
    ["9 AM"]: "9 a. m.",
    ["9:30 AM"]: "9:30 AM",
    ["10 AM"]: "10 a. m.",
    ["10:30 AM"]: "10:30 a. m.",
    ["11 AM"]: "11 a. m.",
    ["11:30 AM"]: "11:30 AM",
    ["12 AM"]: "12 a.m.",
    ["12:30 PM"]: "12:30 p.m.",
    ["1 PM"]: "13:00 horas",
    ["1:30 PM"]: "13:30 horas",
    ["2 PM"]: "2 p.m.",
    ["2:30 PM"]: "2:30 p.m.",
    ["3 PM"]: "3 p.m.",
    ["3:30 PM"]: "3:30 p.m.",
    ["4 PM"]: "4 p.m.",
    ["4:30 PM"]: "4:30 p.m.",
    ["5 PM"]: "5 p.m.",
    ["5:30 PM"]: "5:30 p.m.",
    ["6 PM"]: "6 p.m.",
    ["6:30 PM"]: "6:30 p.m.",
    ["7 PM"]: "7 p.m.",
    ["7:30 PM"]: "19:30 horas",
    ["8 PM"]: "8 p.m.",
    ["8:30 PM"]: "20:30 horas",
    ["9 PM"]: "21:00 horas",
    ["9:30 PM"]: "21:30",
    ["10 PM"]: "22:00 horas",
    ["10:30 PM"]: "22:30 horas",
    ["Send To Friend"]: "Enviar a un amigo",
    ["Because you deserve this."]: "Porque te mereces esto.",
    ["Our specialist will contact you."]:
      "Nuestro especialista se pondrá en contacto con usted.",
    ["Request More Information"]: "Solicitar más información",
    ["Mounting"]: "Montaje",
    ["Select Backing Type"]: "Seleccionar el tipo de respaldo",
    ["Backing Type"]: "Tipo de respaldo",
    ["Select Metal Color"]: "Seleccionar el color del metal",
    ["Your Mounting"]: "Su montaje",
    ["Add To Cart"]: "Añadir a la cesta",
    ["Please Add Alternate Shopping Cart URL"]:
      "Agregue una URL alternativa para el carrito de compras",
    ["Friend's E-Mail is Invalid"]:
      "El correo electrónico del amigo no es válido",
    ["Friend's E-Mail is Compulsory"]:
      "El correo electrónico del amigo es obligatorio",
    ["Friend's Name is Compulsory"]: "El nombre del amigo es obligatorio",
    ["Mounting style Found"]: "Estilo de montaje encontrado",
    ["Choose This Mounting"]: "Elija este montaje",
    ["Mounting Style"]: "Estilo de montaje",
    ["Vendor lot number of the item:"]:
      "Número de lote del proveedor del artículo:",
    ["WholeSale Price:"]: "Precio al por mayor:",
    ["Third Party:"]: "Tercero:",
    ["Diamond ID:"]: "Identificación del diamante:",
    ["Seller Name:"]: "Nombre del vendedor:",
    ["Address:"]: "DIRECCIÓN:",
    ["Test"]: "Prueba",
    ["Similar Diamonds"]: "Diamantes similares",
    ["Florescence"]: "Florescencia",
    ["$"]: "$",
    ["Diamond Details"]: "Detalles del diamante",
    ["Have a question regarding this item? Our specialists are available to assist you. "]:
      "¿Tiene alguna pregunta sobre este artículo? Nuestros especialistas están disponibles para ayudarle.",
    ["Buy Diamond Only"]: "Compre solo diamantes",
    ["Add Your Setting"]: "Añade tu configuración",
    ["Complete Your Ring"]: "Completa tu anillo",
    ["US$"]: "EL DÓLAR AMERICANO$",
    ["-clarity diamond comes accompanied by"]:
      "-El diamante de claridad viene acompañado de",
    ["a diamond grading report from"]:
      "un informe de clasificación de diamantes de",
    ["-color, and"]: "-color, y",
    ["This"]: "Este",
    ["-Carat"]: "-Quilate",
    ["Diamond Grading Report"]: "Informe de clasificación de diamantes",
    ["9.80X7.56X"]: "9,80 x 7,56 x",
    ["100"]: "100",
    ["No items found"]: "No se encontraron artículos",
    ["reset"]: "reiniciar",
    ["your"]: "su",
    ["filters"]: "filtros",
    ["US$2,295"]: "US$2.295",
    ["Stock Number: 177782295"]: "Número de stock: 177782295",
    ["VST"]: "VST",
    ["H"]: "yo",
    ["Password is Compulsory"]: "La contraseña es obligatoria",
    ["Color Panel Updated Successfully"]:
      "Panel de color actualizado con éxito",
    ["Color panel has been reset successfully"]:
      "El panel de color se ha restablecido correctamente",
    ["Color Panel not Updated Successfully"]:
      "El panel de color no se actualizó correctamente",
    ["Color panel has not been reset successfully"]:
      "El panel de color no se ha restablecido correctamente",
    ["Choose Font"]: "Elija la fuente",
    ["LexendDeca-Regular"]: "LexendDeca-Regular",
    ["Times New Roman"]: "Times New Roman",
    ["Cursive"]: "Cursivo",
    ["Monospace"]: "Monoespaciado",
    ["System UI"]: "Interfaz de usuario del sistema",
    ["Hover Effect"]: "Efecto de desplazamiento",
    ["Column Header Accent"]: "Acento en el encabezado de columna",
    ["Link Color"]: "Color del enlace",
    ["Powered By"]: "Desarrollado por",
    ["Select Your"]: "Seleccione su",
    ["Choose Your"]: "Elige tu",
    ["Complete Your"]: "Completa tu",
  },
};
